import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import htmlToImage from 'html-to-image';


const PrintButton = ({id, children, name}) => (<div className="tc mb4 mt2" style={{display: "inline-block"}}>
  <div
    onClick={() => {
      setTimeout(async() => {
        const input = document.getElementById(id);
        const selector = document.querySelectorAll(".pf");
        selector.forEach(element => {
          element.style.margin = "0 auto";
        });
        
//        html2canvas(input, {
//          scale: 3,
//        })
//        
         htmlToImage.toCanvas(input)
        .then((dataUrl) => {
          var imgWidth = 210;
          var pageHeight = 297;
          var imgHeight = dataUrl.height * imgWidth / dataUrl.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm');
          var position = 0;
          doc.addImage(dataUrl, 'JPG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          while (heightLeft >= 30) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(dataUrl, 'JPG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save( `${name ? name: "file"}.pdf`);
          selector.forEach(element => {
            element.style.margin = "13px auto";
          });
        });
      }, 100);
    }}
  >
    {children}
  </div>
</div>);

export default PrintButton;

