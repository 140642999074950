export const URL = "info/users";
export const URL_LOCK_UNLOCK_LOGIN = "action/lockUnlockUser";
export const URL_ACTIVE_CTV = "action/createReferralLink";
export const URL_SEND_EMAIL_SMS = "action/sendEmailSms";
export const URL_SEARCH = "search";
export const URL_SHOW_CONTRACT = "contract";

export const GET_LIST = "GET_INVESTORS_LIST";
export const GET_LIST_DETAIL = "GET_LIST_INVESTORS_DETAIL";
export const SET_PAGE_NUMBER = "SET_PAGE_INVESTORS_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_INVESTORS_SIZE";
export const SET_TOTAL_LIST = "GET_INVESTORS_LIST_TOTAL";
export const SET_FILTER = "SET_INVESTORS_FILTER";
export const SET_MODAL = "SET_INVESTORS_MODAL";



export const SET_SEND_EMAIL_SMS_SUCCESS = "SET_INVESTORS_SEND_EMAIL_SMS_SUCCESS";
export const SET_ACTIVE_CTV_SUCCESS = "SET_INVESTORS_ACTIVE_CTV_SUCCESS";
export const SET_LOCK_UNLOCK_LOGIN_SUCCESS = "SET_INVESTORS_LOCK_UNLOCK_LOGIN_SUCCESS";
export const SET_CHOOSE_LIST = "SET_INVESTORS_CHOOSE_LIST";
export const SET_REPLACE_STATUS = "SET_INVESTORS_REPLACE_STATUS";
export const SET_REPLACE_ACTIVE_CTV = "SET_INVESTORS_REPLACE_ACTIVE_CTV";



