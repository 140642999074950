const transformNumber = (number) => {
  if (number !== undefined && number !== null) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "0";
  }
};
export const transformNumberPrinter = (number) => {
  let leftNumber = Math.floor(number);
  let rightNumber = number - Math.floor(number);
  if (rightNumber > 0.5) {
    leftNumber = leftNumber + 1;
  }
  if (number !== undefined && number !== null) {
    return leftNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return "0";
  }
};
export default transformNumber;
