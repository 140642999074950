import React, { Fragment } from "react";
import {URL} from "./../../../../states/common/constants";
import Iframe from 'react-iframe'
import {transformNumber, transformDateToDateFormal} from "./../../../bases/configs";


const WithdrawImageModal = ({closeModal, contractSelect}) => {
  return (
    <Fragment>
    <div className="content">
    <div className="withdraw-img">
        <div className="withdraw-content">
        <div className="img">{ contractSelect.recipeScanUrl !== null ? <Iframe url={`${URL}/${contractSelect.recipeScanUrl}`} className="iframe" display="initial" position="relative"/> : <img src="images/transform_img.jpg" alt=""/>}<p className="desc">Phiếu chi</p></div>
        <div className="text">
          <div className="text-content">
            <div className="item">
              <p className="label">Người rút tiền</p>
              <p className="value">{contractSelect.investorName}</p>
            </div>
            <div className="item">
              <p className="label">Số tiền rút</p>
              <p className="value">{transformNumber(contractSelect.amount)}</p>
            </div>
            <div className="item">
              <p className="label">Ngày xuất tiền</p>
              <p className="value">{transformDateToDateFormal(contractSelect.processDate)}</p>
            </div>
            <div className="item">
              <p className="label">Người xử lý</p>
              <p className="value">{contractSelect.adminName}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div className="bottom">
            <button className="bottom__btn green__btn" type="button" onClick={closeModal}>Ok</button>
        </div>
        </Fragment>
  );
};

export default WithdrawImageModal;
