import React from 'react';

const Footer = () => {
    return (
        <footer id="footer">
            <p>© 2019 Bytesoft JSC ALL right reserved.</p>
        </footer>
    );
}

export default Footer;
