import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { transformNumber } from "./../../../bases/configs";
import {
  setProcessActiveApi,
  setSuccessActive,
} from "./../../../../states/duck/pages/contractProcessReducer/actions";
import Iframe from "react-iframe";
import PNotify from "pnotify/dist/es/PNotify";
import $ from "jquery";

const BankNoOption = [
  { value: "0491000094872", label: "0491000094872" },
  // { value: "0491000094872", label: '0491000094872' },
  // { value: "0491000094872", label: '0491000094872' },
];
const ContractProcessModal = ({ itemProcess, closeModal }) => {
  const dispatch = useDispatch();
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState("");
  const [uploadScan, setUploadScan] = useState("");
  const [receivePerson, setReceivePerson] = useState("");
  const [sendPerson, setSendPerson] = useState("");
  const [moneyTotal, setMoneyTotal] = useState(itemProcess.amount);
  const [payNumber, setPayNumber] = useState(
    itemProcess.contractNumber.replace("/HĐHT-BYTESOFT", "")
  );
  const successActive = useSelector(
    (state) => state.contractProcess.successActive
  );
  const [buttonActive, setButtonActive] = useState(true);
  const [bankNo, setBankNo] = useState("020049963689");
  const replaceIframe = (id, func) => {
    $(id).attr("src", "");
    $(id).siblings("input:file").val("");
    func("");
  };
  const setImg = (event, id, setFile) => {
    var target = event.target;
    var files = target.files;
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (event) {
        $(id).attr("src", event.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
    setFile(files[0]);
  };
  const onSubmit = () => {
    setButtonActive(false);
    var t = setTimeout(() => {
      setButtonActive(true);
      clearTimeout(t);
    }, 900);
    if (agreeCheck) {
      let formData = new FormData();
      if (itemProcess.transferType === "bank") {
        formData.append("contractId", itemProcess.id);
        formData.append("bankNo", bankNo);
        formData.append("contract", uploadScan);
      } else {
        formData.append("receiver", receivePerson);
        formData.append("sender", sendPerson);
        formData.append("amount", moneyTotal);
        formData.append("recipeNumber", payNumber);
        formData.append("contractId", itemProcess.id);
        formData.append("recipe", uploadReceipt);
        formData.append("contract", uploadScan);
      }
      dispatch(setProcessActiveApi(formData));
    } else {
      PNotify.error({
        title: "Lỗi",
        text: "Bạn phải chọn xác nhận",
        destroy: true,
        delay: 3000,
      });
    }
  };
  const onFinish = () => {
    dispatch(setSuccessActive(false));
    closeModal();
  };
  return (
    <div className="invest-process">
      {!successActive ? (
        <Fragment>
          {" "}
          <div className="head">
            <p className="title">
              {itemProcess.transferType === "bank"
                ? "Bạn đang xử lý đầu tư bằng hình thức chuyển khoản"
                : "Bạn đang xử lý đầu tư bằng hình thức nhận tiền mặt"}
            </p>
          </div>
          <div className="content">
            {itemProcess.transferType === "bank" ? (
              <div className="part">
                <p className="title">
                  Xác nhận đã nhận được tiền chuyển khoản theo các thông tin
                </p>
                <div className="info-content">
                  <p className="desc">
                    Chủ tài khoản:{" "}
                    <span className="bold">
                      {itemProcess.bank
                        ? itemProcess.bank.company.bankAccountHolder
                        : "......................................................"}
                    </span>
                  </p>
                  <p
                    className="desc"
                    style={{ display: "inline-block", paddingRight: "20px" }}
                  >
                    Số tài khoản nhận tiền đầu tư:{" "}
                    <span className="bold">
                      {itemProcess.bank
                        ? itemProcess.bank.company.bankNumber
                        : ".................................."}
                    </span>
                  </p>
                  {
                    // <div className="bank-number" style={{marginTop: "-10px", marginBottom: "20px", width: "200px", display: "inline-block"}}>
                    //   <Select options={BankNoOption} name="bankNo"  value={BankNoOption.filter(option => option.value === bankNo)} onChange={ (newValue) => setBankNo(newValue.value)}/>
                    // </div>
                  }

                  <p className="desc">
                    Ngân hàng:{" "}
                    <span className="bold">
                      {itemProcess.bank
                        ? itemProcess.bank.company.bankName
                        : ".........................................................."}
                    </span>
                  </p>
                  <p className="desc">
                    Chi nhánh:{" "}
                    <span className="bold">
                      {itemProcess.bank
                        ? itemProcess.bank.company.bankBranch
                        : ".........................................................."}
                    </span>
                  </p>
                  <p className="desc">
                    Số tiền:{" "}
                    <span className="bold">
                      {transformNumber(itemProcess.amount)}{" "}
                    </span>
                  </p>
                  <p className="desc">
                    <label
                      htmlFor="checkbox"
                      className={`checkbox__label ${
                        agreeCheck ? "active" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        name="agree"
                        id="checkbox"
                        onChange={(e) => setAgreeCheck(e.target.checked)}
                      />{" "}
                      Xác nhận
                    </label>
                  </p>
                </div>
              </div>
            ) : (
              <div className="part">
                <p className="title">
                  Xác nhận đã nhận được tiền đầu tư bằng tiền mặt
                </p>
                <div className="form-content">
                  <div className="bs-row row-sm-10">
                    <div className="bs-col sm-50-10">
                      <div className="item">
                        <label htmlFor="">Người nhận tiền</label>
                        <input
                          type="text"
                          className="modal__input"
                          name="receivePerson"
                          onChange={(e) => setReceivePerson(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="bs-col sm-50-10">
                      <div className="item">
                        <label htmlFor="">Người nộp tiền</label>
                        <input
                          type="text"
                          className="modal__input"
                          name="sendPerson"
                          onChange={(e) => setSendPerson(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="bs-col sm-50-10">
                      <div className="item">
                        <label htmlFor="">Số tiền</label>
                        <input
                          type="text"
                          className="modal__input"
                          name="moneyTotal"
                          value={transformNumber(itemProcess.amount)}
                          onChange={(e) => setMoneyTotal(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="item">
                        <label htmlFor="">Số phiếu thu</label>
                        <input
                          type="text"
                          className="modal__input"
                          name="payNumber"
                          onChange={(e) => setPayNumber(e.target.value)}
                          value={payNumber}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="bs-col sm-50-10">
                      <div className="item upload-item">
                        <div className="upload__file">
                          <input
                            type="file"
                            className="modal__input"
                            id="upload__img1"
                            onChange={(e) =>
                              setImg(e, "#img1", setUploadReceipt)
                            }
                          />
                          <img
                            src="images/icon_upload_img.gif"
                            alt=""
                            className="img__icon"
                          />
                          <span
                            className={`replace__iframe ${
                              uploadReceipt === "" ? "hidden" : ""
                            }`}
                            onClick={() =>
                              replaceIframe("#img1", setUploadReceipt)
                            }
                          >
                            x
                          </span>
                          <Iframe
                            url=""
                            className={`result__img iframe ${
                              uploadReceipt === "" ? "" : "active"
                            }`}
                            id="img1"
                          />
                        </div>
                        <label htmlFor="">
                          {uploadReceipt === ""
                            ? "Upload ảnh phiếu thu"
                            : uploadReceipt.name}
                        </label>
                      </div>
                      <div className="item">
                        <label
                          htmlFor="checkbox1"
                          className={`checkbox__label ${
                            agreeCheck ? "active" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            name="agree"
                            id="checkbox1"
                            onChange={(e) => setAgreeCheck(e.target.checked)}
                          />{" "}
                          Xác nhận
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="part">
              <p className="title">
                Xác nhận đã hoàn thành hợp đồng ký kết 2 bên
              </p>
              <div className="upload__img">
                <input
                  type="file"
                  className="modal__input"
                  id="upload__img2"
                  onChange={(e) => setImg(e, "#img2", setUploadScan)}
                />
                <img
                  src="images/icon_upload_img.gif"
                  alt=""
                  className="img__icon"
                />
                <span
                  className={`replace__iframe ${
                    uploadScan === "" ? "hidden" : ""
                  }`}
                  onClick={() => replaceIframe("#img2", setUploadScan)}
                >
                  x
                </span>
                <Iframe
                  url=""
                  className={`result__img iframe ${
                    uploadScan === "" ? "" : "active"
                  }`}
                  id="img2"
                />
              </div>
              <p className="desc">
                {uploadScan === ""
                  ? "Upload bản scan hợp đồng đã ký kết"
                  : uploadScan.name}
              </p>
            </div>
          </div>
          <div className="bottom">
            {buttonActive ? (
              <button
                className="bottom__btn green__btn"
                type="button"
                onClick={onSubmit}
              >
                kích hoạt đầu tư
              </button>
            ) : (
              <button
                className="bottom__btn gray__btn"
                type="button"
                style={{
                  background: "gray",
                  cursor: "not-allowed",
                }}
              >
                kích hoạt đầu tư
              </button>
            )}
            <button
              className="bottom__btn red__btn"
              type="button"
              onClick={onFinish}
            >
              hủy
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="success-content">
            <div className="head">
              <p className="desc">Kích hoạt thành công gói đầu tư</p>
            </div>
            <div className="content">
              <img src="images/icon_success_process.gif" alt="" />
            </div>
            <div className="bottom">
              <button
                className="bottom__btn green__btn"
                type="button"
                onClick={onFinish}
              >
                OK
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ContractProcessModal;
