import React, { Fragment} from 'react';
import RelativeLink from "./../../bases/shared/relativeLink.js";
const SettingPage= () => {
    return (
        <Fragment>
        <RelativeLink title="Cấu hình hệ thống"/>
        <section className="section-list">
            <div className="module module-list">
                <div className="module-header">
                    <h2 className="title"><img src="images/title_img.png" alt=""/> Cấu hình hệ thống</h2>
                </div>
                <div className="module-content">
                    <p className="desc">Update version sau</p>
                </div>
            </div>
        </section>
        </Fragment>
    );
}

export default SettingPage;
