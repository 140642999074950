import React, { useState } from "react";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import {getSearchApi, getPageSize} from "./../../../states/duck/pages/investorsListReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"


const option1 = [
    { value: "", label: 'Tất cả' },
    { value: "0", label: 'Không chức vụ' },
    { value: "1", label: 'Nhân viên' },
    { value: "2", label: 'Phó phòng' },
    { value: "3", label: 'Trưởng phòng' },
    { value: "4", label: 'Giám đốc' }
  ]
  

const option2 = [
    { value: "", label: 'Tất cả' },
    { value: "0", label: 'NĐT' },
    { value: "1", label: 'CTV' },
  ]


const FilterList = () => {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [investType, setInvestType] = useState("");
  const listStore = useSelector(state => state.investorsList.pageSize)
  const [list, setList] = useState(listStore); 
  const dispatch = useDispatch();
  const onSubmitForm = () => {
    dispatch(getSearchApi({
      name: name,
      level: position,
      type: "contract",
      investType
    },list, 1));
     dispatch(getPageSize(list));

  }
  return (
    <div className="search-content">
      <div className="bs-row row-xs-10">
        <div className="bs-col sm-40-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Họ tên/CMND</label>
            <input type="text" className="search__input" placeholder="" name="name" onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col sm-20-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Chức vụ</label>
            <Select options={option1} name="position"  value={option1.filter(option => option.value === position)} onChange={ (newValue) => setPosition(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col sm-20-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Loại đầu tư</label>
            <Select options={option2} name="position"  value={option2.filter(option => option.value === investType)} onChange={ (newValue) => setInvestType(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col sm-20-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
      </div>
      <button className="search__btn" type="button" onClick={ onSubmitForm }>Lọc</button>
    </div>
  );
};

export default FilterList;

