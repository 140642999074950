import React from "react";
import {transformNumber} from "./../../../bases/configs";
import Iframe from 'react-iframe'
import {URL} from "./../../../../states/common/constants"

const InfoCashModal = ({contractSelect}) => {
  console.log("contractSelect", contractSelect);
  return (
    <div className="trade-info">
    <div className="head">
    <p className="title">Thông tin</p>
  </div>
  <div className="content">
      <div className="info-content">
        <div className="img">
          {
          contractSelect.recipeScanUrl !== null ?
           <Iframe url={`${URL}/${contractSelect.recipeScanUrl}`} className="iframe" display="initial" position="relative" />:   <img src="images/transform_img.jpg" alt=""/>
        }
      <p className="desc">Giấy biên nhận nhận tiền</p></div>
        <div className="text">
          <div className="text-content">
            <p className="desc">Người nhận tiền đầu tư: <span className="bold">{contractSelect.status !== "pending" ?contractSelect.investorName : ""}</span></p>
            <p className="desc">Số giấy biên nhận: <span>{contractSelect.status !== "pending" ? contractSelect.transferContent : ""}</span></p>
            <p className="desc">Số tiền: <span>{transformNumber(contractSelect.amount)}</span></p>
          </div>
        </div>
      </div>
      
  </div>
    </div>
  );
};

export default InfoCashModal;