import React from "react";
import PaginationTable from "./../../../bases/shared/paginationTable.js";
import { useSelector } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../../bases/configs"



const SendSmsModal = () => {
  const detailsTableList = useSelector(state => state.investorsList.detailsTableList);
  console.log(detailsTableList)
  return (
    <div className="show-contract">
      <div className="head">
        <p className="title">Chi tiết hợp đồng</p>
      </div>
      <div className="content">
      <div className="info-content">
      <div className="bs-row row-xs-10">
      <div className="bs-col xs-33-10">
        <div className="item">
          <p className="label">Nhà đầu tư</p>
          <p className="value">{detailsTableList.user.name}</p>
        </div>
      </div>
      <div className="bs-col xs-33-10">
        <div className="item">
          <p className="label">CMND</p>
          <p className="value">{detailsTableList.user.docId}</p>
        </div>
      </div>
      <div className="bs-col xs-33-10">
        <div className="item">
          <p className="label">Điện thoại</p>
          <p className="value">{detailsTableList.user.phone}</p>
        </div>
      </div>
      <div className="bs-col xs-33-10">
        <div className="item">
          <p className="label">Email</p>
          <p className="value">{detailsTableList.user.email}</p>
        </div>
      </div>
      <div className="bs-col xs-66-10">
        <div className="item">
          <p className="label">Điạ chỉ</p>
          <p className="value">
            {detailsTableList.user.address}
          </p>
        </div>
      </div>
    </div>
      </div>

        <div className="table-content">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Mã hợp đồng</th>
                  <th>Tiền đầu tư</th>
                  <th>Kỳ hạn</th>
                  <th>Kích hoạt</th>
                  <th>Hết kỳ hạn</th>
                </tr>
              </thead>
              <tbody>
                {
                    detailsTableList.contracts.map((item, index) => {
                        return (
                            <tr key={index}>
                            <td>{item.contractNumber}</td>
                            <td>{transformNumber(item.amount)}</td>
                            <td>{item.term}</td>
                            <td>{transformDateToDateFormal(item.activeDate)}</td>
                            <td>{transformDateToDateFormal(item.endDate)}</td>
                          </tr>
                        )
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
        <PaginationTable />
      </div>
    </div>
  );
};

export default SendSmsModal;
