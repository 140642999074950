export const URL = "info/admins";
export const URL_LOCK_UNLOCK_LOGIN = "auth/local/toggle-lock-admin";
export const URL_CREATE_ACCOUNT = "auth/local/register";
export const URL_EDIT_ACCOUNT = "auth/local/edit";


export const GET_LIST = "GET_CREATE_ACCOUNT_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_CREATE_ACCOUNT_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_CREATE_ACCOUNT_SIZE";
export const SET_TOTAL_LIST = "GET_CREATE_ACCOUNT_LIST_TOTAL";
export const SET_MODAL = "SET_CREATE_ACCOUNT_MODAL";
export const SET_EDIT = "SET_CREATE_ACCOUNT_EDIT";
export const SET_CREATE_ACCOUNT = "SET_CREATE_ACCOUNT_CREATE";
export const SET_EDIT_ACCOUNT = "SET_EDIT_ACCOUNT_CREATE";



export const SET_SEND_EMAIL_SMS_SUCCESS = "SET_CREATE_ACCOUNT_SEND_EMAIL_SMS_SUCCESS";
export const SET_CREATE_ACCOUNT_LOCK_UNLOCK_USER_SUCCESS = "SET_CREATE_ACCOUNT_LOCK_UNLOCK_USER_SUCCESS";
export const SET_CHOOSE_LIST = "SET_CREATE_ACCOUNT_CHOOSE_LIST";
export const SET_REPLACE_STATUS = "SET_CREATE_ACCOUNT_REPLACE_STATUS";



