import React, { useState, useEffect } from "react";
import useForm from 'react-hook-form';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { editAccountApi } from "./../../../../states/duck/pages/createAccountReducer/actions"

const EditForm = yup.object().shape({
   email: yup.string().email().required(),
  name: yup.string().required(),
  home: yup.boolean().default(() => false).required(),
  invest: yup.boolean().default(() => false).required(),
  contract: yup.boolean().default(() => false).required(),
  withdrawManager: yup.boolean().default(() => false).required(),
  liquidationManager: yup.boolean().default(() => false).required(),
  investProcess: yup.boolean().default(() => false).required(),
  liquidationProcess: yup.boolean().default(() => false).required(),
  withdrawProcess: yup.boolean().default(() => false).required(),
  createAccount: yup.boolean().default(() => false).required(),
  settingSystem: yup.boolean().default(() => false).required(),
  statisticalProcess: yup.boolean().default(() => false).required(),
});








const EditModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const listChose = useSelector(state => state.createAccount.listUserChoose);
  const [triggerList, setTriggerList] = useState(false);
  const [listState, setListState] = useState(listChose);
  useEffect(() => {
    setListState(listChose);
    console.log("fafdasfdafda")
  }, [listChose])
  let formData;

  const { register, errors, handleSubmit, getValues } = useForm({
    mode: "onChange",
    validationSchema: EditForm
  });

  const onCancel = () => {
    closeModal();
  }
  const onSubmit = (data) => {
    const list = { ...data };
    var listResult = [];
    delete list.email; delete list.name;
     for (var i in list){
      if(list[i] === true){
          convertToArr(i,listResult)
          }
        }
      listResult = listResult.sort((a,b) => a - b);
    if (!triggerListValidate()) {
      formData = {
        id: listState[0].id,
        name: data.name,
        email: data.email,
        permissions: listResult
      }
      dispatch(editAccountApi(formData));
      closeModal();
    }
  }

  const triggerListValidate = () => {
    const dataCurrent = Object.values(getValues());
    let shouldTrigger = true;
    dataCurrent.map(item => {
      if (item === true) shouldTrigger = false;
    })

    if (shouldTrigger) {
      setTriggerList(true);
      return true
    } else {
      setTriggerList(false);
      return false
    }
  }
  console.log("listState", listState)
  // console.log("render edit modal");

  return (
    <div className="edit-modal">
      <div className="head">
        <p className="title">Chỉnh sửa</p>
      </div>
      {
        listState.length > 0 ? (
          <div className="content">
        <div className="edit-content">
          <div className="create-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bs-row row-sm-15">
                <div className="bs-col sm-50-15">
                  <div className="item">
                    <label >Email đăng nhập</label>
                    <input className="search__input"
                      name="email"
                      type="email"
                      defaultValue={listState[0].email}
                      ref={register({
                        required: "email vaild"
                      })}
                    />
                    {errors.email && <p style={{ color: "red" }} >{errors.email.message}</p>}
                  </div>
                </div>
                <div className="bs-col sm-50-15">
                  <div className="item">
                    <label >Tên nhân viên</label>
                    <input className="search__input"
                      name="name"
                      type="text"
                      defaultValue={listState[0].name}
                      ref={register({
                        required: "name valid"
                      })}
                    />
                    {errors.name && <p style={{ color: "red" }}>{errors.name.message}</p>}
                  </div>
                </div>
                <div className="bs-col sm-100-15">
                  <div className="item">
                    <label htmlFor="" className="special">Quyền hạn</label>
                    <div className="checkbox-list" name="list" ref={register}>
                    <input type="checkbox" id="home-modal" name="home" defaultChecked={listState[0].permissions.indexOf("1") >= 0 ? true : false} onChange={() => triggerListValidate()} ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="home-modal" >Trang chủ</label>
                      <input type="checkbox" id="invest-modal" name="invest" defaultChecked={listState[0].permissions.indexOf("2") >= 0 ? true : false} onChange={() => triggerListValidate()} ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="invest-modal" >Danh sách đầu tư</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" id="contract-modal" defaultChecked={listState[0].permissions.indexOf("3") >= 0 ? true : false} name="contract" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="contract-modal">Danh sách hợp đồng</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("4") >= 0 ? true : false} id="withdrawManager-modal" name="withdrawManager" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="withdrawManager-modal">Quản lý rút tiền</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("5") >= 0 ? true : false} id="liquidationManager-modal" name="liquidationManager" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="liquidationManager-modal">Quản lý thanh lý</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("6") >= 0 ? true : false} id="investProcess-modal" name="investProcess" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="investProcess-modal">Xử lý đầu tư</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("7") >= 0 ? true : false} id="liquidationProcess-modal" name="liquidationProcess" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="liquidationProcess-modal">Xử lý thanh lý</label>
                          <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("8") >= 0 ? true : false} id="withdrawProcess-modal" name="withdrawProcess" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="withdrawProcess-modal">Xử lý rút tiền</label>
                          <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("9") >= 0 ? true : false} id="createAccount-modal" name="createAccount" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="createAccount-modal">Tạo tài khoản quản trị</label>
                          <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("10") >= 0 ? true : false} id="settingSystem-modal" name="settingSystem" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="settingSystem-modal">Cấu hình hệ thống</label>

                        <input onChange={() => triggerListValidate()} type="checkbox" defaultChecked={listState[0].permissions.indexOf("11") >= 0 ? true : false} id="statisticalProcess-modal" name="statisticalProcess" ref={register({
                          required: {
                            message: "checkbox"
                          }
                        })} />
                        <label htmlFor="statisticalProcess-modal">Tra cứu lịch sử</label>
                    </div>
                    {triggerList && <p style={{ color: "red" }}>Chọn ít nhất một trường</p>}
                  </div>
                </div>
              </div>
              <div className="bottom">
                <button className="bottom__btn green__btn" type="submit" onSubmit={() => {
                  onSubmit()
                }}> Chỉnh sửa </button>
                <button className="bottom__btn red__btn" type="button" onClick={onCancel}>Hủy</button>
              </div>
            </form>
          </div> </div>
      </div>
        ) : ("")
      }
    </div>
  );
};

export default EditModal;


const convertToArr = (run, arr) => {
  switch (run) {
     case "home":
    arr.push(1);
    break;
   case "invest":
    arr.push(2);
    break;
  case "contract":
  arr.push(3);
    break;
  case "withdrawManager":
    arr.push(4);
    break;
  case "liquidationManager":
    arr.push(5);
    break;
  case "investProcess":
    arr.push(6);
    break;
    case "liquidationProcess":
    arr.push(7);
    break;
  case "withdrawProcess":
  arr.push(8);
    break;
  case "createAccount":
    arr.push(9);
    break;
  case "settingSystem":
    arr.push(10);
    break;
  case "statisticalProcess":
    arr.push(11);
    break;
  default:
      break;

  }
}
