import React, {useState} from "react";
import PaginationTable from "../../bases/shared/paginationTable.js";
import { useSelector, useDispatch } from 'react-redux';
import { BsModal } from "../../../components/bases/shared";
import ModalList from "./modalList"
import {transformNumber, transformDateToDateFormal} from "./../../bases/configs";
import {getContractApi, getPageNumber, setToggleModal, setLiquidationStatusApi, getSearchApi, setSuccessActive, setDetails} from "./../../../states/duck/pages/liquidationProcessReducer/actions";

const InvestorsList = () => {
	const dispatch = useDispatch();
  const adminId = JSON.parse(localStorage.getItem("adminId")).adminId;
  const [modalType, setModalType] = useState("");
  const showModalType = value => {
    setModalType(value);
    dispatch(setToggleModal(true));
	dispatch(setLiquidationStatusApi({
      "finishId": value.id,
      "status": "waitSign"
    }));
  };
  const toggleModal = useSelector(state => state.liquidationProcess.toggleModal);
  const tableList = useSelector(state => state.liquidationProcess.tableList);
  const total = useSelector(state => state.liquidationProcess.total);
  const pageSize = useSelector(state => state.liquidationProcess.pageSize);
  const pageNumber = useSelector(state => state.liquidationProcess.pageNumber);
   const filter = useSelector(state => state.liquidationProcess.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
       dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));
  }

	return (
		<div className="table-content process-table">
			<div className="table">
				<table>
					<thead>
						<tr>
							<th>Mã HĐ</th>
							<th>Họ tên nhà đầu tư</th>
							<th>Tiền đầu tư</th>
							<th>Số tiền sau thanh lý</th>
							<th>Ngày yêu cầu</th>
							<th>Người xử lý</th>
							<th>Trạng thái</th>
							<th>Hành động</th>
						</tr>
					</thead>
					<tbody>
						{tableList.map((item, index) => {
							return (
								<tr key={index}>
									<td>
										{item.contractNumber}
									</td>
									<td>{item.investorName}</td>
									<td className="no-wrap">{transformNumber(item.contractAmount)}</td>
									<td className="no-wrap">{transformNumber(item.finishAmount)}</td>
									<td>{transformDateToDateFormal(item.updatedAt)}</td>
									<td>{item.adminName}</td>
									 <td>{item.status === "pending" ? "Chưa xử lý" : item.status === "waitSign" ? "chờ ký phiếu chi" : item.status="waitTransfer" ? "chờ chuyển tiền" : ""}</td>
									<td>
										{
											// item.processAdmin !== adminId && item.adminName !== null ? <button className={`process__btn  gray__btn`} type="button">Thanh lý</button> : <button className="process__btn" type="button" onClick={() => showModalType(item)}>Thanh lý</button>
										}
										<button className="process__btn" type="button" onClick={() => showModalType(item)}>Thanh lý</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<BsModal
					className="process-modal"
					toggleModal={toggleModal}
					onClick={() => {
						dispatch(setToggleModal(false)); dispatch(setDetails({}));  dispatch(setSuccessActive(false));
						if(filter.type){
							getSearchApi(filter,pageSize,pageNumber)
						}else{
							dispatch(getContractApi(pageSize, pageNumber));
						}
					}}
				>
				{ toggleModal ?
					<ModalList itemProcess={modalType} closeModal={() =>  {
						dispatch(setToggleModal(false)); 
						dispatch(setSuccessActive(false));
						if(filter.type){
							getSearchApi(filter,pageSize,pageNumber)
						}else{
							dispatch(getContractApi(pageSize, pageNumber));
						}
					}}/> : ""
				}
				</BsModal>
			</div>
			 <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
		</div>
	);
};

export default InvestorsList;
