import React, { useState } from "react";
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux';
import {getSearchApi, getPageSize, getPageNumber} from "./../../../states/duck/pages/contractProcessReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"



const FilterList = () => {
  const [name, setName] = useState("");
  const pageSize = useSelector(state => state.contractProcess.pageSize);
  const [list, setList] = useState(pageSize);
  const dispatch = useDispatch();
   const onSubmit = () => {
    dispatch(getSearchApi({
      name,
      status: "",
      type: "invest"
    },list, 1));
    dispatch(getPageSize(list));
    dispatch(getPageNumber(1));
  }
  return (
    <div className="search-content invest-search">
      <div className="bs-row row-xs-10">
        <div className="bs-col sm-60-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Mã hợp đồng/Tên nhà đầu tư</label>
            <input type="text" className="search__input" placeholder="" name="name" value={name} onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col sm-40-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
      </div>
      <button className="search__btn" type="button" onClick={ onSubmit }>Lọc</button>
    </div>
  );
};

export default FilterList;

