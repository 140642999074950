export const URL = "search/history";
export const URL_GET_CONTRACT_BY_NAME = "contract/investor"
export const GET_LIST = "GET_HISTORY_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_HISTORY_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_HISTORY_SIZE";
export const SET_TOTAL_LIST = "GET_HISTORY_LIST_TOTAL";
export const SET_FILTER = "SET_HISTORY_FILTER";
export const SET_SHOW_TABLE_BY_TYPE = "SET_HISTORY_SHOW_TABLE_BY_TYPE";
export const SET_CONTRACT_NUMBER_BY_NAME = "SET_HISTORY_CONTRACT_NUMBER_BY_NAME";
export const REFRESH_CONTRACT_NUMBER_BY_NAME = "REFRESH_HISTORY_CONTRACT_NUMBER_BY_NAME";



export const INVEST_HISTORY = "invest";
export const FINISH_HISTORY = "finish";
export const PROFIT_HISTORY = "profit";
export const BONUS_HISTORY = "bonus";
export const WITHDRAW_PROFIT_HISTORY = "withdrawProfit";
export const WITHDRAW_BONUS_HISTORY = "withdrawBonus";


