import React, { Fragment, useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import {  useSelector, useDispatch } from 'react-redux';
import { getInfoApi } from "./../../../states/duck/pages/homeReducer/actions";
import { transformNumber } from "./../../bases/configs"




const InputMoney = ({item}) => {
    const dispatch = useDispatch();
    useEffect(() => {
         dispatch(getInfoApi({
            type: item.type
        }))
    }, [dispatch, item.type]);
 
    const total = useSelector(state => state.home[item.type]);
    const [timeStart, setTimeStart] = useState("");
    const [timeEnd, setTimeEnd] = useState(new Date());
    const [activeSubmit, setActiveSubmit] = useState(false);

    useEffect(() => {
        if(timeStart !== ""){
            if(timeStart.getTime() <= timeEnd.getTime()){
                setActiveSubmit(true)
            }else{
                setActiveSubmit(false)
            }
        }
    }, [timeEnd, timeStart])

     const onStartDateChange = date => {
        setTimeStart(date);
    };
    const onEndDateChange = date => {
        setTimeEnd(date);
    };

    const submit = () => {
       dispatch(getInfoApi({
           type: item.type,
           startDate: timeStart,
           enDate: timeEnd
       }))
    }
    return <div className="bs-col lg-33 md-33 sm-50 tn-100">
        <div className="input-money">
            <span className="mark-border-top"></span>
            <div className="input-title">
                { item.title}
            </div>
            <div className="value-content">
                <input type="text" className="money" name="total-money" value={total} disabled/>
                <p className="value">{transformNumber(total)}</p>
            </div>
         <div className="date-time">
            {
                item.type !== "predictProfit" ? (
                    <Fragment>
                    <div className="bs-row">
                    <div className="bs-col xs-50 tn-100">
                        <div className="time-start">
                            <p>Thời gian từ</p>
                            <DatePicker value={timeStart} onChange={ (date) => {
                                onStartDateChange(date)
                            }} />
                        </div>
                    </div>
                    <div className="bs-col xs-50 tn-100">
                        <div className="time-end">
                            <p>Tới</p>
                            <DatePicker value={timeEnd} onChange={ (date) => {
                                onEndDateChange(date)
                            }} />
                        </div>
                    </div>
                </div>
                {
                    activeSubmit ? <button className="send__btn" type="button" onClick={submit}>Xem</button> : ""
                }
                </Fragment>
                ):("")
            }
            </div>
            <span className="mark-border-bottom"></span>
        </div>
    </div>
}
export default InputMoney;

