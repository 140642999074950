

import React, { useState } from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { BsModal } from "../../../components/bases/shared";
import WithdrawImageModal from "./modalList/withdrawImageModal";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../bases/configs";
import {getContractApi, getPageNumber, setToggleModal, getSearchApi} from "./../../../states/duck/pages/withdrawManagerReducer/actions";


const TableList = () => {
  	 const dispatch = useDispatch();
  const [modalType, setModalType] = useState("");
  const showModalType = value => {
    setModalType(value);
    dispatch(setToggleModal(true));
  };
  const toggleModal = useSelector(state => state.withdrawManager.toggleModal);
  const tableList = useSelector(state => state.withdrawManager.tableList);
  const total = useSelector(state => state.withdrawManager.total);
  const pageSize = useSelector(state => state.withdrawManager.pageSize);
  const pageNumber = useSelector(state => state.withdrawManager.pageNumber);
    const filter = useSelector(state => state.withdrawManager.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
       dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));

  }
  return (
      <div className="table-content">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th>Người rút tiền</th>
              <th>Số tiền rút</th>
              <th>Ngày xuất tiền</th>
              <th>Người xử lý</th>
              <th>Phiếu chi</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                if(item.status !== "pending"){
                  return (
                      <tr key={index}>
                        <td>{item.investorName}</td>
                        <td className="no-wrap">{transformNumber(item.amount)}</td>
                        <td>{transformDateToDateFormal(item.processDate)}</td>
                        <td>{item.adminName}</td>
                        <td><button className="show__info" onClick={() => showModalType(item)}><img src="images/icon_img.gif" alt=""/></button></td>
                      </tr>
                    );
                  }})
                
                }
            </tbody>
          </table>
        </div>
        <BsModal
        className="withdraw-modal"
        toggleModal={toggleModal}
        onClick={() => dispatch(setToggleModal(false))}
      >
       <WithdrawImageModal closeModal={()=>dispatch(setToggleModal(false))} contractSelect={modalType} />
      </BsModal>
         <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;

