import React, { Fragment, useEffect} from 'react';
import FilterList from "./filterList";
import TableList from "./tableList";
import RelativeLink from "./../../bases/shared/relativeLink.js";
import ModalList from "./modalList";
import { useDispatch } from 'react-redux';
import {PAGE_SIZE} from "./../../../states/common/constants";
import {getContractApi} from "./../../../states/duck/pages/investorsListReducer/actions";
const InvestorsListPage = () => {
    const dispatchContractList = useDispatch();
    useEffect(() => {
        dispatchContractList(getContractApi(PAGE_SIZE, 1));
    }, [dispatchContractList])
    return (
        <Fragment>
        <RelativeLink title="Danh sách nhà đầu tư" />
        <section className="section-list">
            <div className="module module-list">
                <div className="module-header">
                    <h2 className="title"><img src="images/title_img.png" alt=""/> Danh sách nhà đầu tư</h2>
                </div>
                <div className="module-content">
                    <FilterList />
                    <ModalList />
                    <TableList/>
                </div>
            </div>
        </section>
        </Fragment>
    );
}

export default InvestorsListPage;

