import * as types from "./constants";

const initialState = {
    toggleModal : false,
    infoPosition: [],
    listPositionDetail: [],
    invest: 0,
    profitPay: 0,
    bonusPay: 0,
    investorWithdraw: 0,
    profitWithdraw: 0,
    bonusWithdraw: 0,
    finishNoEndTerm: 0,
    finishEndTerm: 0,
    finishAmount: 0,
    predictProfit: 0,
    userRole: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case types.SET_INFO_LIST:
        if(action.value.type === "invest"){
            return {...state, invest : action.value.total}
        }else if(action.value.type === "profitPay"){
            return {...state, profitPay : action.value.total}
        }else if(action.value.type === "bonusPay"){
            return {...state, bonusPay : action.value.total}
        }else if(action.value.type === "investorWithdraw"){
            return {...state, investorWithdraw : action.value.total}
        }else if(action.value.type === "profitWithdraw"){
            return {...state, profitWithdraw : action.value.total}
        }else if(action.value.type === "bonusWithdraw"){
            return {...state, bonusWithdraw : action.value.total}
        }else if(action.value.type === "finishNoEndTerm"){
            return {...state, finishNoEndTerm : action.value.total}
        }else if(action.value.type === "finishEndTerm"){
            return {...state, finishEndTerm : action.value.total}
        }else if(action.value.type === "finishAmount"){
            return {...state, finishAmount : action.value.total}
        }else if(action.value.type === "predictProfit"){
            return {...state, predictProfit : action.value.total}
        }else{
            return state;
        }
        
    case types.SET_MODAL:
        return {...state, toggleModal : action.value }
    case types.SET_POSITION_DETAIL_LIST:
        return {...state, listPositionDetail : action.value }
    case types.SET_POSITION_LIST:
        return {...state, infoPosition : action.value }
    default:
        return state
    }
}
export default reducer;