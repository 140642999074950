import React from "react";
import {transformNumber} from "./../../../bases/configs";


const InfoTransferModal = ({contractSelect}) => {
  return (
    <div className="trade-info">
    <div className="head">
    <p className="title">Thông tin</p>
  </div>
  <div className="content">
      <p className="desc"><span className="bold">1. Tài khoản nhà đầu tư:</span></p>
      <p className="desc">Chủ tài khoản: <span className="bold">{contractSelect.bank.user.bankAccountHolder}</span></p>
      <p className="desc">Số tài khoản: <span className="bold">{contractSelect.bank.user.bankNumber}</span></p>
      <p className="desc">Ngân hàng: <span className="bold">{contractSelect.bank.user.bankName}</span></p>
      <p className="desc">Chi nhánh: <span className="bold">{contractSelect.bank.user.bankBranch}</span></p>
      <p className="desc">Số tiền: <span className="bold">{transformNumber(contractSelect.amount)}</span></p>
      <p className="desc">Nội dung chuyển khoản: <span>{contractSelect.contractNumber}</span></p>
       <p className="desc"><span className="bold">2. Tài khoản Bytesoft:</span></p>
      <p className="desc">Chủ tài khoản: <span className="bold">{contractSelect.bank.company.bankAccountHolder}</span></p>
      <p className="desc">Số tài khoản: <span className="bold">{contractSelect.bank.company.bankNumber}</span></p>
      <p className="desc">Ngân hàng: <span className="bold">{contractSelect.bank.company.bankName}</span></p>
      <p className="desc">Chi nhánh: <span className="bold">{contractSelect.bank.company.bankBranch}</span></p>
      <span></span>
  </div>
    </div>
  );
};

export default InfoTransferModal;
