import * as constants from './constants';
import {callApi} from './../../../common';


export const getUserApi = (pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${pageSize}/${pageNumber}`, "GET", null, function(res){
            dispatch(getContract(res.data.admins));
            dispatch(getTotalList(res.data.total));
    });
}



export const setLockUnlockUser = (body) => {
    return dispatch =>  callApi(`${constants.URL_LOCK_UNLOCK_LOGIN}`, "POST", body, function(res){
        dispatch(setReplaceStatus(body));
    });
}

export const createAccountApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_CREATE_ACCOUNT}`, "POST", body, function(res){
        dispatch(setCreateAccount({ ...res.data.admin, name: body.name}));
        console.log("res.data.admin", res.data.admin)
    });
}

export const editAccountApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_EDIT_ACCOUNT}/${body.id}`, "POST", body, function(res){
        dispatch(setEdit(body));
    });
}








export const setCreateAccount = (value) => {
    return {
        type: constants.SET_CREATE_ACCOUNT,
        value
    }
}

export const getContract = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const getTotalList = (value) => {
    return {
        type: constants.SET_TOTAL_LIST,
        value
    }
}
export const getPageNumber = (value) => {
    return {
         type: constants.SET_PAGE_NUMBER,
        value
    }
}
export const getPageSize = (value) => {
    return {
         type: constants.SET_PAGE_SIZE,
        value
    }
}


export const setToggleModal = (value) => {
    return {
        type: constants.SET_MODAL,
        value
    }
}



export const setChooseList = (value) => {
    return {
        type: constants.SET_CHOOSE_LIST,
        value
    }
}

export const setReplaceStatus = (value) => {
    return {
        type: constants.SET_REPLACE_STATUS,
        value
    }
}
export const setEdit = (value) => {
    return {
        type: constants.SET_EDIT_ACCOUNT,
        value
    }
}


