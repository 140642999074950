import * as types from './constants';
import * as common from "./../../../common/constants"
var initialState = {
    filter: {
        keyword: "",
    },
    toggleModal: false,
   pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    total: "",
    tableList: [],
    lockUnlockLoginSuccess: false,
    listUserChoose: []
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_TOTAL_LIST:
            return {...state, total: action.value};
        case types.SET_PAGE_NUMBER:
            return {...state, pageNumber: action.value};
        case types.SET_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_PAGE_SIZE:
            return {...state, pageSize: action.value};
        case types.SET_CHOOSE_LIST:
            return {...state, listUserChoose: action.value};
        case types.SET_CREATE_ACCOUNT:
            var tableListMirror = [...state.tableList];
            tableListMirror.unshift(action.value);
            return {...state, tableList: tableListMirror};
        case types.SET_REPLACE_STATUS:
            tableListMirror = [...state.tableList];
            var newsListUserChoose = [...state.listUserChoose];
            newsListUserChoose[0].isLocked = !newsListUserChoose[0].isLocked;
            var index = -1;
            for(let i = 0; i < tableListMirror.length; i++){
                if(tableListMirror[i].id === action.value.id){
                    index = i;
                }
            }
            if(index >= 0){
                tableListMirror[index].isLocked = !tableListMirror[index].isLocked;
            }
            
            return {...state, tableList: tableListMirror, listUserChoose: newsListUserChoose};
        case types.SET_EDIT_ACCOUNT:
            tableListMirror = [...state.tableList];
            var listUserChooseMirror = [...state.listUserChoose];
            index = -1;
            for(let i = 0; i < tableListMirror.length; i++){
                if(tableListMirror[i].id === action.value.id){
                    index = i;
                }
            }
            if(index >= 0){
                tableListMirror.splice(index, 1, action.value);
                listUserChooseMirror.splice(0, 1, action.value);
            }
            
            return {...state, tableList: tableListMirror, listUserChoose: listUserChooseMirror};
        default:
            return state;
    }
}
export default reducer;