

import React from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber} from "./../../bases/configs"
import {getContractApi, getPageNumber, setChooseList, getSearchApi} from "./../../../states/duck/pages/investorsListReducer/actions";



const TableList = () => {
  const dispatch = useDispatch();
  const listChose = useSelector(state => state.investorsList.listContractChoose);
  const choseCheckBox = (e, id) => {
    var newListChose = []
    if(e.target.checked){
      newListChose.push(id);
    }
    dispatch(setChooseList(newListChose));
  };
  const tableList = useSelector(state => state.investorsList.tableList);
  const total = useSelector(state => state.investorsList.total);
  const pageSize = useSelector(state => state.investorsList.pageSize);
  const pageNumber = useSelector(state => state.investorsList.pageNumber);
  const filter = useSelector(state => state.investorsList.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
      dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));
  }
  const triggerCheckBox = (item) => {
    const oldId = listChose.length > 0 ? listChose[0].id : "";
      var newListChose = []
      if(oldId !== item.id){
         newListChose.push(item);
      }
      dispatch(setChooseList(newListChose));
  }

  return (
      <div className="table-content">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th></th>
              <th>Họ và tên</th>
              <th>CMND</th>
              <th>Chức vụ</th>
              <th>Tiền đầu tư</th>
              <th>Loại đầu tư</th>
              <th>Lãi đã nhận</th>
              <th>Thưởng đã nhận</th>
              <th>Doanh số</th>
              <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                return (
                  <tr key={index} onClick={() => triggerCheckBox(item)}>
                    <td>
                      <input type="checkbox" className="select__box" onChange={(e)=>choseCheckBox(e,item)} checked={listChose[0] && listChose[0].id === item.id ? "checked" : ""}/>
                    </td>
                    <td className="no-wrap">{item.name}</td>
                    <td>{item.docId}</td>
                    <td className="no-wrap">{
                      item.level === 0 ? "Không chức vụ" :
                      item.level === 1 ? "Nhân viên" :
                      item.level === 2 ? "Phó phòng" :
                      item.level === 3 ? "Trưởng phòng" :
                      item.level === 4 ? "Giám đốc" :
                      ""
                      }</td>
                    <td>{transformNumber(item.totalInvest)}</td>
                    <td>{item.referralCode ? "CTV" : "NĐT" }</td>
                    <td>{transformNumber(item.totalProfit)}</td>
                    <td>{transformNumber(item.totalBonus)}</td>
                    <td>{item.totalInviteeInvest}</td>
                    <td>{item.lockReason !== "0" ? "Chặn" : "Hoạt động"}</td>
                  </tr>
                );

              })}
            </tbody>
          </table>
        </div>
        <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;

