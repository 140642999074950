import React from "react";
import {URL} from "./../../../../states/common/constants";
import Iframe from 'react-iframe'

const ContractImagesModal = ({contractSelect}) => {

  return (
    <div className="contract-img">
      <div className="head">
        <p className="title">Xem hợp đồng</p>
      </div>
      <div className="content">
          <div className="img-content">
            {
          contractSelect.contractScanUrl !== null ?
          <Iframe url={`${URL}/${contractSelect.contractScanUrl}`} className="iframe" display="initial" position="relative"/>:
          <img src="images/transform_img.jpg" alt=""/>
        }
         
          </div>
      </div>
      </div>
  );
};

export default ContractImagesModal;
