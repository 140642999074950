import React, { useState, Fragment } from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { BsModal } from "../../../components/bases/shared";
import ContractProcessModal from "./modalList/contractProcessModal";
import ShowModal from "./modalList/showModal";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../bases/configs"
import {getContractApi, getPageNumber, setStartActiveApi, setToggleModal, getSearchApi} from "./../../../states/duck/pages/contractProcessReducer/actions";


const TableList = () => {
  const dispatch = useDispatch();
  const adminId = JSON.parse(localStorage.getItem("adminId")).adminId;
  const [modalType, setModalType] = useState("");
  const [modalAction, setModalAction] = useState("")
  const showModalType = (value, text) => {
    setModalType(value);
    setModalAction(text);
    dispatch(setToggleModal(true));
    if(adminId !== value.processAdmin && text === "action"){
       dispatch(setStartActiveApi(value.id))
    }
  };
  const toggleModal = useSelector(state => state.contractProcess.toggleModal);
  const tableList = useSelector(state => state.contractProcess.tableList);
  const total = useSelector(state => state.contractProcess.total);
  const pageSize = useSelector(state => state.contractProcess.pageSize);
  const pageNumber = useSelector(state => state.contractProcess.pageNumber);
  const filter = useSelector(state => state.contractProcess.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
       dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));
  }
  return (
      <div className="table-content process-table">
      <div className="table">
        <table>
          <thead>
          <tr>
            <th>Mã hợp đồng</th>
            <th>Họ tên <br/> nhà đầu tư</th>
            <th>Tiền đầu tư</th>
            <th>Hình thức</th>
            <th>Nội dung</th>
            <th>Ngày yêu cầu kích hoạt</th>
            <th>Trạng thái</th>
            <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="no-wrap"> <button className="show__info" type="button" onClick={
                    () => showModalType(item, "show")
                  }>{item.contractNumber}</button> </td>
                  <td>{item.investorName}</td>
                  <td className="no-wrap">{transformNumber(item.amount)}</td>
                  <td>{item.transferType === "bank" ? "Chuyển khoản" : "Tiền mặt"}</td>
                  <td>{item.transferContent}</td>
                  <td>{transformDateToDateFormal(item.createdAt)}</td>
                  <td>{item.adminName !== null ? item.adminName + " đang xử lý" : "Chưa xử lý"}</td>
                  <td>
                  {
                    item.processAdmin !== adminId && item.adminName !== null ? <button className={`process__btn  gray__btn`} type="button">Kích hoạt</button> : <button className="process__btn" type="button" onClick={() => showModalType(item, "action")}>Kích hoạt</button>
                  }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
        <BsModal
        className="process-modal"
        toggleModal={toggleModal}
        onClick={() => {
          dispatch(setToggleModal(false));
           if(filter.type){
              dispatch(getSearchApi(filter,pageSize,pageNumber));
            }else{
              dispatch(getContractApi(pageSize, pageNumber));
            }
          }}
      >
       { toggleModal ?
       <Fragment>
       {
         modalAction === "action" ?  <ContractProcessModal itemProcess={modalType} closeModal={() =>  {
           dispatch(setToggleModal(false));
           if(filter.type){
              dispatch(getSearchApi(filter,pageSize,pageNumber));
            }else{
              dispatch(getContractApi(pageSize, pageNumber));
            }
           }}/> : <ShowModal  itemProcess={modalType} closeModal={() =>  {
              dispatch(setToggleModal(false));
           }}/>
       }
       </Fragment>
         : ""
       }
      </BsModal>
        <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;