import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getActiveCTVApi  } from "./../../../../states/duck/pages/investorsListReducer/actions"


const ActiveCTVModal = ({closeModal}) => {
   const dispatch = useDispatch();
  const listChose = useSelector(state => state.investorsList.listContractChoose);
  const activeCTVSuccess = useSelector(state => state.investorsList.activeCTVSuccess);





  const onSubmit = () => {
      dispatch(getActiveCTVApi({
        userId: listChose[0].id,
      }))
  }
  const onFinish = () => {
    closeModal();
  }
  return (
    <div className="block-login">
      <div className="head">
        <p className="title">Kích hoạt cộng tác viên</p>
        <p className="desc">
        Bạn đang kích hoạt CTV cho nhà đầu tư: { listChose.length > 0 ? listChose[0].name : ""}
      </p>
      </div>
      {
        !activeCTVSuccess ? 
        <Fragment>
        <div className="bottom">
            <button className="bottom__btn green__btn" type="button" onClick={onSubmit}>Kích hoạt CTV</button>
            <button className="bottom__btn red__btn" type="button" onClick={onFinish}>hủy</button>
        </div>
        </Fragment>: <Fragment>
        <div className="content">
            <p className="desc">Bạn đã kích hoạt CTV cho nhà đầu tư { listChose.length > 0 ? listChose[0].name : ""} thành công</p>
        </div>
        <div className="bottom">
            <button className="bottom__btn green__btn" type="button" onClick={onFinish}>OK</button>
        </div>
        </Fragment>
      }
      </div>
  );
};

export default ActiveCTVModal;