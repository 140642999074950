import React, { Fragment, useState } from "react";
import { BsModal } from "../../../../components/bases/shared";
import ManagerLoginModal from "./managerLoginModal";
import EditModal from "./editModal";
import { useSelector, useDispatch } from 'react-redux';
import { setToggleModal } from "./../../../../states/duck/pages/createAccountReducer/actions";

const ModalList = () => {
 const dispatch = useDispatch();
  const [showModal, setModalType] = useState("");
  const showModalType = value => {
    setModalType(value);
    dispatch(setToggleModal(true));
  };

  const toggleModal =  useSelector(state => state.createAccount.toggleModal);
  const listChose = useSelector(state => state.createAccount.listUserChoose);
  return (
    <Fragment>
      <div className="table-tools create-tools">
        {
          listChose.length > 0 ? (
            <Fragment>
            {
              listChose[0].isLocked ? (
                <button
                  className="table__btn red__btn"
                  type="button"
                  onClick={() => showModalType("block-login")}
                >
                  Chặn đăng nhập
                </button>
              ):(
                 <button
                    className="table__btn orange__btn"
                    type="button"
                    onClick={() => showModalType("on-login")}
                  >
                    Mở đăng nhập
                  </button>
              )
            }
              <button
                className="table__btn blue__btn"
                type="button"
                onClick={() => showModalType("edit")}
              >
                Sửa
              </button>
            </Fragment>
          ): ""
        }
        
      </div>
      <BsModal
      className={showModal === "show-contract" ? "show-contract" : ""}
        toggleModal={toggleModal}
        onClick={() => {
          dispatch(setToggleModal(false));
          }}
      >
        {showModal === "block-login" ? (
          <ManagerLoginModal closeModal={()=>{dispatch(setToggleModal(false))}}/>
        ) : showModal === "on-login" ? (
          <ManagerLoginModal closeModal={()=>{dispatch(setToggleModal(false))}}/>
        ) : showModal === "edit" ? (
          <EditModal closeModal={()=>dispatch(setToggleModal(false))} />
        ) : (
          ""
        )}
      </BsModal>
    </Fragment>
  );
};

export default ModalList;
