
import * as constants from "./constants"
import {callApi} from './../../../common';

export const getInfoApi = (type) => {
    return dispatch => callApi(`${constants.URL}`, "POST", type, function(res){
        if(type.type !== "userRole"){
             dispatch(getInfoList({
                type: type.type,
                total: res.data.total
            }));
        }else{
            dispatch(getPositionList(res.data.total))
        }
    });
}

export const getPositionDetailApi = (index) => {
    return dispatch => callApi(`${constants.URL}${constants.URL_QUERY}${index}`, "POST", {
	    "type": "userRole"
    },function(res){
             dispatch(getPositionDetailList(res.data.users));
    })
}




export const getInfoList = (value) => {
    return {
        type: constants.SET_INFO_LIST,
        value
    }
}
export const getPositionList = (value) => {
    return {
        type: constants.SET_POSITION_LIST,
        value
    }
}
export const getPositionDetailList = (value) => {
    return {
        type: constants.SET_POSITION_DETAIL_LIST,
        value
    }
}




export const setToggleModal = (value) => {
    return {
        type: constants.SET_MODAL,
        value
    }
}
export const homeModalSearch = (value) => {
    return {
        type: constants.CHANGE_VALUE_MODAL,
        value
    }
}