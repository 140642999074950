import * as types from './constants';
import * as common from "./../../../common/constants"
var initialState = {
    filter: {
        processor: "",
        investor: "",
        contractNumber: "",
        startDate: "2019/11/01",
        endDate: new Date(),
        type: types.INVEST_HISTORY
    },
    tableType: false,
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    total: "",
    tableList: [],
    contractOption: [
        { value: "", label: 'Tất cả' }
    ]
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_TOTAL_LIST:
            return {...state, total: action.value};
        case types.SET_PAGE_NUMBER:
            return {...state, pageNumber: action.value};
        case types.SET_PAGE_SIZE:
            return {...state, pageSize: action.value};
        case types.SET_CONTRACT_NUMBER_BY_NAME:
            var newContractOption = [ { value: "", label: 'Tất cả' }];
            var listContractByName = action.value;
            if(listContractByName.length > 0){
                for(let i = 0; i < listContractByName.length; i++){
                    newContractOption.push({
                        value: listContractByName[i], 
                        label: listContractByName[i]
                    })
                }
            }
            return {...state, contractOption: newContractOption};
        case types.REFRESH_CONTRACT_NUMBER_BY_NAME:
            return {...state, contractOption: [{ value: "", label: 'Tất cả' }]};
        case types.SET_SHOW_TABLE_BY_TYPE:
            return {...state, tableType: action.value};
        case types.SET_FILTER:
            return {...state,
                filter: {
                    processor: action.value.processor,
                    investor: action.value.investor,
                    contractNumber: action.value.contractNumber,
                    startDate: action.value.startDate,
                    endDate: action.value.endDate,
                    type: action.value.type
                }
            };
        default:
            return state;
    }
}
export default reducer;