import React, {Fragment} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setLockUnlockUser } from "./../../../../states/duck/pages/createAccountReducer/actions"

const BlockLoginModal = ({closeModal, manager}) => {
  const dispatch =  useDispatch()
  const listChose = useSelector(state => state.createAccount.listUserChoose);
 
  const onSubmit = () => {
    dispatch(setLockUnlockUser({
      adminId: listChose[0].id
    }));
    closeModal();
  }
  const onCancel = () => {
    closeModal();
    
  }
  return (
    <div className="manager-login">
    {
      listChose.length > 0 ? (
        <Fragment>
        <div className="head">
        <p className="desc">
        {listChose[0].isLocked ? `Bạn đang mở khóa tài khoản đăng nhập của ${listChose[0].name}, bạn có chắc chắn?` : `Bạn đang khóa tài khoản đăng nhập của ${listChose[0].name}, bạn có chắc chắn?`}
      </p>
      </div>
      <div className="content">
      {
        listChose[0].isLocked ? <img src="images/icon_active.gif" alt=""/> :  <img src="images/icon_deactive.gif" alt=""/>
      }
       </div>
        </Fragment>
      ) : ""
    }
      
     
      <div className="bottom">
      <button className="bottom__btn green__btn" type="button" onClick={onSubmit}>Đồng ý</button>
      <button className="bottom__btn red__btn" type="button" onClick={onCancel}>hủy</button>
  </div>
      </div>
  );
};

export default BlockLoginModal;
