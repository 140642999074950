import React, { Fragment, useState, useEffect } from "react";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { useSelector, useDispatch } from 'react-redux';
import * as constants from "./../../../states/duck/pages/historyReducer/constants"
import { getHistoryApi, getFilter, showTableByType, getContractNumberByNameApi, refreshContractNumberByName } from "./../../../states/duck/pages/historyReducer/actions"
import {formatDate} from "./../../../states/common/index";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"









const FilterList = () => {
  const dispatch = useDispatch();
  const nameStore = useSelector(state => state.history.filter.name);
  const typeStore = useSelector(state => state.history.filter.type);
  const contractStore = useSelector(state => state.history.filter.contract);
  const startDateStore = useSelector(state => new Date(state.history.filter.startDate));
  const endDateStore = useSelector(state => new Date(state.history.filter.endDate));
  const pageSizeStore = useSelector(state => state.history.pageSize);
  const contractOptionStore = useSelector(state => state.history.contractOption);



  const [name, setName] = useState(contractStore);
  const [processPerson, setProcessPerson] = useState(nameStore);
  const [startDate, setStartDate] = useState(startDateStore);
  const [endDate, setEndDate] = useState(endDateStore);
  const [list, setList] = useState(pageSizeStore);
  const [contractNumber, setContractNumber] = useState("");
  const [contractType, setContractType] = useState("");
  const [profitType, setProfitType] = useState("");
  const [bonusType, setBonusType] = useState("");
  const [transferType, setTransferType] = useState("");
  const [contractOption, setContractOption] = useState(contractOptionStore);
  useEffect(() => {
    setContractOption(contractOptionStore);
  }, [contractOptionStore])
  const onStartDateChange = date => {
    setStartDate(date);
  };
  const onEndDateChange = date => {
    setEndDate(date);
  };
  const onSubmit = () => {
    var transferType;
    if (typeStore === constants.FINISH_HISTORY){
      transferType = contractType
    }else if (typeStore === constants.PROFIT_HISTORY){
      transferType = profitType
    }else if (typeStore === constants.BONUS_HISTORY){
      transferType = bonusType
    }
    dispatch(getHistoryApi({
      type: typeStore,
      processor: processPerson,
      investor: name,
      contractNumber: contractNumber,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      transferType
    }, list, 1));
  }
  const onFilter = (type) => {
      dispatch(getFilter({
      type,
    }));
    dispatch(showTableByType(false));
  }
  const searchContract = () => {
    if(name !== ""){
      dispatch(getContractNumberByNameApi({
        investor: name,
      }))
    }else{
        dispatch(refreshContractNumberByName())
    }
  }
  

  return (
    <Fragment>
      <div className="select-history">
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.INVEST_HISTORY ? "active" : ""}`}  onClick={() => onFilter(constants.INVEST_HISTORY)}>Lịch sử đầu tư</label>
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.FINISH_HISTORY ? "active" : ""}`} onClick={() => onFilter(constants.FINISH_HISTORY)}>Lịch sử thanh lý</label>
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.PROFIT_HISTORY ? "active" : ""}`} onClick={() => onFilter(constants.PROFIT_HISTORY)}>Lịch sử nhận lãi</label>
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.BONUS_HISTORY ? "active" : ""}`} onClick={() => onFilter(constants.BONUS_HISTORY)}>Lịch sử nhận thưởng</label>
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.WITHDRAW_PROFIT_HISTORY ? "active" : ""}`} onClick={() => onFilter(constants.WITHDRAW_PROFIT_HISTORY)}>Lịch sử rút lãi</label>
        <label htmlFor="timeLimit" className={`radio__label ${typeStore === constants.WITHDRAW_BONUS_HISTORY ? "active" : ""}`} onClick={() => onFilter(constants.WITHDRAW_BONUS_HISTORY)}>Lịch sử rút thưởng</label>
      </div>
      <div className="search-content history-content">
        <div className="bs-row row-md-10 row-sm-5 row-xs-10">
          <div className={`bs-col xs-50-10 ${typeStore === constants.WITHDRAW_PROFIT_HISTORY || typeStore === constants.WITHDRAW_BONUS_HISTORY ? "md-15-10 sm-15-5" : "md-20-10 sm-20-5"}`}>
            <div className="item">
              <label htmlFor="">Tên NĐT/CMND</label>
              <input type="text" className="search__input" placeholder="" value={name} name="name" onChange={(e) => setName(e.target.value)} onBlur={searchContract}/>
            </div>
          </div>
          {
            ( typeStore === constants.INVEST_HISTORY || typeStore === constants.FINISH_HISTORY || typeStore === constants.PROFIT_HISTORY ) ? 
            <div className="bs-col sm-20-5 md-20-10 xs-50-10">
            <div className="item">
              <label htmlFor="">Mã HĐ</label>
               <Select options={contractOption} name="list"  value={contractOption.filter(option => option.value === contractNumber)} onChange={ (newValue) => setContractNumber(newValue.value)}/>
            </div>
          </div> : ""
          }
          {
            (typeStore === constants.INVEST_HISTORY || typeStore === constants.FINISH_HISTORY) ? 
            <div className="bs-col sm-20-5 md-20-10 xs-50-10">
            <div className="item">
              <label htmlFor="">Người xử lý</label>
               <input type="text" className="search__input" placeholder="" name="name" value={processPerson} onChange={(e) => 
               setProcessPerson(e.target.value)}/>
            </div>
          </div> : ""
          }
           {
            (typeStore === constants.BONUS_HISTORY) ? 
            <div className="bs-col sm-20-5 md-20-10 xs-50-10">
            <div className="item">
              <label htmlFor="">Loại thưởng</label>
               <Select options={bonusTypeOption} name="list"  value={bonusTypeOption.filter(option => option.value === bonusType)} onChange={ (newValue) => setBonusType(newValue.value)}/>
            </div>
          </div> : ""
          }
          
          {
            typeStore === constants.FINISH_HISTORY ? <div className="bs-col sm-20-5 md-20-10xs-50-10">
            <div className="item">
              <label htmlFor="">Kiểu thanh lý</label>
               <Select options={contractTypeOption} name="list"  value={contractTypeOption.filter(option => option.value === contractType)} onChange={ (newValue) => setContractType(newValue.value)}/>
            </div>
          </div> : ""
          }
            {
            typeStore === constants.PROFIT_HISTORY ? <div className="bs-col sm-20-5 md-20-10 xs-50-10">
            <div className="item">
              <label htmlFor="">Loại lãi</label>
               <Select options={profitTypeOption} name="list"  value={profitTypeOption.filter(option => option.value === profitType)} onChange={ (newValue) => setProfitType(newValue.value)}/>
            </div>
          </div> : ""
          }
           {
            (typeStore === constants.WITHDRAW_PROFIT_HISTORY || typeStore === constants.WITHDRAW_BONUS_HISTORY ) ? <div className="bs-col sm-15-5 md-15-10 xs-50-10">
            <div className="item">
              <label htmlFor="">Hình thức</label>
               <Select options={transferTypeOption} name="list"  value={transferTypeOption.filter(option => option.value === transferType)} onChange={ (newValue) => setTransferType(newValue.value)}/>
            </div>
          </div> : ""
          }
          
          <div className="bs-col sm-20-5 md-20-10 xs-50-10 ">
              <div className="item">
              <label htmlFor="">Từ ngày</label>
                <DatePicker onChange={onStartDateChange} value={startDate} />
              </div>
            </div>
            <div className="bs-col sm-20-5 md-20-10 xs-50-10">
              <div className="item">
              <label htmlFor="">Đến ngày</label>
                <DatePicker onChange={onEndDateChange} value={endDate} />
              </div>
            </div>
           
          <div className={`bs-col xs-50-10 ${typeStore === constants.WITHDRAW_PROFIT_HISTORY || typeStore === constants.WITHDRAW_BONUS_HISTORY ? "sm-15-5 md-15-10" : "sm-20-5 md-20-10"}`}>
            <div className="item">
              <label htmlFor="">Hiển thị</label>
              <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
            </div>
          </div>
           <div className="bs-col sm-15-5 md-15-10 xs-100-10">
            <div className="item">
             <button className="search__btn" type="button" onClick={ onSubmit }>Lọc</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FilterList;

const contractTypeOption = [
  { value: "", label: 'Tất cả' },
  { value: "noEndTerm", label: 'Trước hạn' },
  { value: "endTerm", label: 'Đúng hạn' },
  { value: "noTerm", label: 'Không kỳ hạn' }
]
const bonusTypeOption = [
  { value: "", label: 'Tất cả' },
  { value: "invite", label: 'Thưởng môi giới' },
  { value: "share", label: 'Thưởng đồng hưởng' },
  { value: "manager", label: 'Thưởng quản lý' },
]
const profitTypeOption = [
  { value: "", label: 'Tất cả' },
  { value: "invest", label: 'Lãi đầu tư' },
  { value: "invite", label: 'Lãi môi giới' },
]
const transferTypeOption = [
  { value: "", label: 'Tất cả' },
  { value: "money", label: 'Tiền mặt' },
  { value: "bank", label: 'Chuyển khoản' },
]

