import React from "react";
import PaginationTable from "../../../bases/shared/paginationTable";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../../bases/configs"
import {getHistoryApi, getPageNumber} from "./../../../../states/duck/pages/historyReducer/actions";
const LiquidationTableList = () => {
   const dispatch = useDispatch();
  const tableList = useSelector(state => state.history.tableList);
  const filter = useSelector(state => state.history.filter)
  const total = useSelector(state => state.history.total);
  const pageSize = useSelector(state => state.history.pageSize);
  const pageNumber = useSelector(state => state.history.pageNumber);
  const getPage = (number) => {
    dispatch(getHistoryApi(filter, pageSize, number));
    dispatch(getPageNumber(number));

  }
  return (
      <div className="table-content liquidation-history">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th>Mã hợp đồng</th>
              <th>Tên nhà đầu tư</th>
              <th>CMND</th>
              <th>Số tiền đầu tư</th>
              <th>Số tiền nhận về</th>
              <th>Ngày thanh lý</th>
              <th>Kiểu thanh lý</th>
              <th>Người thanh lý</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="no-wrap">{item.contractNumber}</td>
                    <td>{ item.investor }</td>
                    <td>{item.docId}</td>
                    <td>{transformNumber(item.contractAmount)}</td>
                    <td>{transformNumber(item.finishAmount)}</td>
                    <td className="no-wrap">{transformDateToDateFormal(item.processDate)}</td>
                    <td>{item.type === "noEndTerm" ? "Trước hạn" : item.type === "endTerm" ? "Đúng hạn" : item.type === "noTerm" ? "Không kỳ hạn" : ""}</td>
                    <td>{item.processor}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
          <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default LiquidationTableList;

