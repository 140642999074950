import * as types from './constants';
import * as common from "./../../../common/constants"
var initialState = {
    filter: {
        keyword: "",
    },
    toggleModal: false,
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    total: "",
    tableList: [],
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_TOTAL_LIST:
            return {...state, total: action.value};
        case types.SET_PAGE_NUMBER:
            return {...state, pageNumber: action.value};
        case types.SET_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_PAGE_SIZE:
            return {...state, pageSize: action.value};
        case types.SET_FILTER:
            return {...state,
                filter: action.value
            };
        default:
            return state;
    }
}
export default reducer;