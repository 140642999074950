export const URL = "https://api-admin.bytesoft.vn";
// export const URL = "http://113.190.253.172:5899";
// export const URL = "http://113.190.253.172:5899";
// export const URL = "http://103.35.65.173:5898";
export const PAGE_SIZE = 10;
export const OPTION_PAGE_SIZE = [
    { value: 5, label: "5" },
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 300, label: '300' },
    { value: 900, label: '900' },
]

