import * as constants from './constants';
import {callApi} from './../../../common';


export const getContractApi = (pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${constants.URL_TYPE}/process/${pageSize}/${pageNumber}`, "GET", null, function(res){
            dispatch(getContract(res.data.data));
            dispatch(getTotalList(res.data.total));
    });
}


export const setLiquidationStatusApi = (body) => {
    return dispatch => callApi(`${constants.URL_WITHDRAW_STATUS}`, "POST", body, function(res){
        dispatch(setDetails(res.data.finishDetail));
    });
}

export const setLiquidationProcessApi = (body) => {
    return dispatch => callApi(`${constants.URL_WITHDRAW_PROCESS}`, "POST", body, function(res){
        dispatch(setReplaceAfterSuccess(body.get('finishId')));
        dispatch(setSuccessActive(true));
    });
}

export const getSearchApi = (body, pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL_SEARCH}/${pageSize}/${pageNumber}`, "POST", body, function(res){
            dispatch(getContract(res.data.results));
            dispatch(getTotalList(res.data.total));
            dispatch(getFilter(body));
    });
}


export const getContract = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const getTotalList = (value) => {
    return {
        type: constants.SET_TOTAL_LIST,
        value
    }
}
export const getPageNumber = (value) => {
    return {
         type: constants.SET_PAGE_NUMBER,
        value
    }
}
export const getPageSize = (value) => {
    return {
         type: constants.SET_PAGE_SIZE,
        value
    }
}
export const getFilter = (value) => {
    return {
        type: constants.SET_FILTER,
        value
    }
}



export const setToggleModal = (value) => {
    return {
        type: constants.SET_MODAL,
        value
    }
}
export const setSuccessActive = (value) => {
    return {
        type: constants.SET_ACTIVE_SUCCESS,
        value
    }
}
export const setReplaceAfterSuccess = (value) => {
    return {
        type: constants.SET_REPLACE_AFTER_SUCCESS,
        value
    }
}
export const setDetails = (value) => {
    return {
        type: constants.SET_DETAILS,
        value
    }
}