import * as constants from './constants';
import {callApi} from './../../../common';


export const getContractApi = (pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${constants.URL_TYPE}/all/${pageSize}/${pageNumber}`, "GET", null, function(res){
            dispatch(getContract(res.data.data));
            dispatch(getTotalList(res.data.total));
    });
}

export const getSearchApi = (body, pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL_SEARCH}/${pageSize}/${pageNumber}`, "POST", body, function(res){
            dispatch(getContract(res.data.results));
            dispatch(getTotalList(res.data.total));
            dispatch(getFilter(body));
       
    });
}




export const getContract = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const getTotalList = (value) => {
    return {
        type: constants.SET_TOTAL_LIST,
        value
    }
}
export const getPageNumber = (value) => {
    return {
         type: constants.SET_PAGE_NUMBER,
        value
    }
}
export const getPageSize = (value) => {
    return {
         type: constants.SET_PAGE_SIZE,
        value
    }
}
export const getFilter = (value) => {
    return {
        type: constants.SET_FILTER,
        value
    }
}

export const setToggleModal = (value) => {
    return {
        type: constants.SET_MODAL,
        value
    }
}
