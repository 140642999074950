import React, { useState } from "react";
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { getSearchApi, getPageSize} from "./../../../states/duck/pages/contractManagerReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"



const option1 = [
    { value: "", label: 'Tất cả' },
    { value: 'pending', label: 'Chờ kích hoạt' },
    { value: 'active', label: 'Kích hoạt' },
    { value: 'waitFinish', label: 'Chờ thanh lý ' },
    { value: 'end', label: 'Kết thúc' },
  ]
 
const FilterList = () => {
    const [name, setName] = useState("");
  const [position, setPosition] = useState("");
   const pageSize = useSelector(state => state.contractManager.pageSize);
  const [list, setList] = useState(pageSize);
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(getSearchApi({
      name,
      status: position,
      type: "contract"
    },list, 1));
    dispatch(getPageSize(list));
  }
  return (
    <div className="search-content">
      <div className="bs-row row-xs-10">
        <div className="bs-col sm-40-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Mã HĐ/Họ tên</label>
            <input type="text" className="search__input" placeholder="" name="name" onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col sm-30-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Trạng thái</label>
            <Select options={option1} name="position"  value={option1.filter(option => option.value === position)} onChange={ (newValue) => setPosition(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col sm-30-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
      </div>
      <button className="search__btn" type="button" onClick={ onSubmit }>Lọc</button>
    </div>
  );
};

export default FilterList;

