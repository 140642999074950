import React, { Fragment, useState, useEffect } from 'react';
import { HeaderLeft, Header, Footer, HomePage, InvestorsListPage, ContractManagerPage, LiquidationManagerPage, SettingPage, WithdrawManagerPage, HistoryPage, CreateAccountPage, ContractProcessPage, WithdrawProcessPage, LiquidationProcessPage, LoginPage } from '../../components';
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setAuthToken} from "./../../states/duck/pages/loginPage/actions";

const RoutePrivate = ({path, children,exact}) => {
  var dispatch = useDispatch()
  const token_localStorage = localStorage.getItem('token');
   if(token_localStorage && token_localStorage !== ""){
     dispatch(setAuthToken(token_localStorage))
   }

  const token = useSelector(state => state.token);
  if(token !== "" && token !== null){
    return  <Route path={path} exact={exact} children={() => children} />
  }else{
    return <Redirect
      to={{
        pathname: "/login",
      }}
    />
  }
}
const Layout = () => {
  const navVisible = useSelector(state => state.headerLeft.navVisible);
  const dispatchToggleNav = useDispatch();
  const [width, setWidth] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const token = useSelector(state => state.token);
  useEffect(() => {
    if(localStorage.getItem("adminId")){
      var adminId = JSON.parse(localStorage.getItem("adminId"));
    }
    if(adminId && adminId.permissions){
      setPermissions(adminId.permissions);
    }
  }, [token]);
    useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    if( document.getElementsByClassName("main-left")[0]){
       if (width >= 992) {
      document.getElementsByClassName("main-left")[0].style.transform = "translateX(0%)";
    } else {
      document.getElementsByClassName("main-left")[0].style.transform = "translateX(-100%)";
      dispatchToggleNav({ type: "CHANGE_TOGGLE_NAV", value : true });
    }
    }
  }, [dispatchToggleNav, width]);

  return (
    <Fragment>
      <Router>
        <div className={`${navVisible ? "main-right__big" : "main-right__thin"} main-right`} >
        <Route  children={({history})=>{
           if(history.location.pathname !== "/login"){
              const index = findIndex(history.location.pathname, routerCollection);
              const checkPosition = permissions.indexOf(index.toString());
              if(checkPosition < 0 && permissions.length > 0){
                return <Redirect to={
                  routerCollection[permissions[0] - 1].path
                } />
              }
           }
          }
         } />
         <Route exact children={({history})=>{
           if(history.location.pathname !== "/login"){
             return  <Header/>
           }
          }
         } />
          <main id="main">
            <div className="main-content">
              <Switch>
                <Route path="/login" exact children={()=> <LoginPage/>} />
                {
                  routerCollection.map((item, index) => {
                    if(permissions.indexOf(item.id) >= 0){
                      return <RoutePrivate key={index} path={item.path} children={item.components} exact={item.exact}/>
                    }else{
                      return null;
                    }
                  })
                }
                {/* <Route path="/" exact children={<HomePage />} /> */}
              </Switch>
            </div>
          </main>
          <Route exact children={({history})=>{
           if(history.location.pathname !== "/login"){
             return  <Footer/>
           }else {return ""}
          }
         } />
        </div>
        <Route exact children={({history})=>{
           if(history.location.pathname !== "/login"){
            return <div className={`${navVisible ? "main-left__big" : "main-left__thin"} main-left`}>
              <HeaderLeft/>
            </div>
           }else {return ""}
          }
         } />
      </Router>
    </Fragment>
  );
}

export default Layout;
const findIndex = (text, arr) => {
  let index = -1;
  for(let i = 0; i < arr.length; i++){
    if(text === arr[i].path){
      index = i;
    }
  }
  return index;
}

const routerCollection = [
  {
    id: "1",
    path: "/",
    components: <HomePage />,
    exact: true
  },
  {
    id: "2",
    path: "/investor-list",
    components: <InvestorsListPage />,
    exact: true
  },
  {
    id: "3",
    path: "/contract-manager",
    components: <ContractManagerPage />,
    exact: true
  },
  {
    id: "4",
    path: "/withdraw-manager",
    components:  <WithdrawManagerPage />,
    exact: true
  },
  {
    id: "5",
    path: "/liquidation-manager",
    components: <LiquidationManagerPage />
  },
  {
    id: "6",
    path: "/invest-process",
    components: <ContractProcessPage />,
    exact: true
  },
  {
    id: "7",
    path: "/withdraw-process",
    components: <WithdrawProcessPage />,
    exact: true
  },
  {
    id: "8",
    path: "/liquidation-process",
    components: <LiquidationProcessPage />,
    exact: true
  },
  {
    id: "9",
    path: "/create-account",
    components: <CreateAccountPage />,
    exact: true
  },
  {
    id: "10",
    path: "/setting",
    components: <SettingPage />,
    exact: true
  },
  {
    id: "11",
    path: "/history",
    components:  <HistoryPage />,
    exact: true
  }

];
export{
  routerCollection
}
