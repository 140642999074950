import * as types from './constants';
import * as common from "./../../../common/constants"
var initialState = {
    filter: {
        keyword: "",
    },
    toggleModal: false,
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    total: "",
    tableList: [],
    successActive: false,
    details: {}
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_TOTAL_LIST:
            return {...state, total: action.value};
        case types.SET_PAGE_NUMBER:
            return {...state, pageNumber: action.value};
        case types.SET_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_PAGE_SIZE:
            return {...state, pageSize: action.value};
        case types.SET_ACTIVE_SUCCESS:
            return {...state, successActive: action.value};
        case types.SET_REPLACE_AFTER_SUCCESS:
             var tableListMirror = [...state.tableList];
            var index = -1;
            for(var i = 0; i < tableListMirror.length; i++){
                if(tableListMirror[i].id === action.value){
                    index = i;
                }
            }
            if(index >= 0){
                tableListMirror.splice(index, 1)
            }
            return {...state, tableList: tableListMirror, total: (state.total -1)};
       case types.SET_FILTER:
            return {...state,
                filter: action.value
            };
       case types.SET_DETAILS:
            if(action.value){
                return {...state,
                details: action.value
            };
            }
            return state;
        default:
            return state;
    }
}
export default reducer;