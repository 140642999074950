import React, { Fragment,useEffect } from 'react';
import FilterList from "./filterList";
import TableList from "./tableList";
import RelativeLink from "./../../bases/shared/relativeLink.js";
import { useDispatch } from 'react-redux';
import {PAGE_SIZE} from "./../../../states/common/constants";
import {getContractApi} from "./../../../states/duck/pages/liquidationProcessReducer/actions";
const LiquidationProcessPage = () => {
    const dispatchContractList = useDispatch();
  useEffect(() => {
    dispatchContractList(getContractApi(PAGE_SIZE, 1));
  }, [dispatchContractList])
    return (
        <Fragment>
            <RelativeLink />
            <section className="section-list section-andlingLiquidation">
                <div className="module module-list">
                    <div className="module-header">
                        <h2 className="title"><img src="images/title_img.png" alt="" /> Xử lý thanh lý</h2>
                    </div>
                    <div className="module-content">
                        <FilterList/>
                        <TableList />
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default LiquidationProcessPage;
