export const URL = "info/get";
export const URL_TYPE = "contracts";
export const URL_SEARCH = "search";
export const URL_START_ACTIVE = "contract/start-active"
export const URL_PROCESS_ACTIVE = "contract/active"
export const GET_LIST = "GET_CONTRACT_PROCESS_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_CONTRACT_PROCESS_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_CONTRACT_PROCESS_SIZE";
export const SET_TOTAL_LIST = "GET_CONTRACT_PROCESS_LIST_TOTAL";
export const SET_FILTER = "SET_CONTRACT_PROCESS_FILTER";
export const SET_START_ACTIVE = "SET_CONTRACT_PROCESS_START_ACTIVE";
export const SET_MODAL = "SET_CONTRACT_PROCESS_MODAL";
export const SET_ACTIVE_SUCCESS = "SET_CONTRACT_PROCESS_ACTIVE_SUCCESS";
export const SET_REPLACE_AFTER_SUCCESS = "SET_REPLACE_CONTRACT_PROCESS_AFTER_SUCCESS";



