import { combineReducers } from "redux";
import {header, headerLeft, footer} from './layout';
import liquidationProcess from './pages/liquidationProcessReducer';
import contractProcess from './pages/contractProcessReducer';
import contractManager from './pages/contractManagerReducer';
import withdrawManager from './pages/withdrawManagerReducer';
import createAccount from './pages/createAccountReducer';
import history from './pages/historyReducer';
import liquidationManager from './pages/liquidationManagerReducer';
import withdrawProcess from './pages/withdrawProcessReducer';
import investorsList from './pages/investorsListReducer';
import token from './../duck/pages/loginPage';
import home from './pages/homeReducer'; // home


const rootReducer = combineReducers({
    footer,
    header,
    headerLeft,
    home,
    liquidationProcess,
    token,
    contractProcess,
    investorsList,
    withdrawProcess,
    contractManager,
    withdrawManager,
    liquidationManager,
    history,
    createAccount
});
export default rootReducer;