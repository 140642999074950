import React, {Fragment, useState} from "react";
const paginationGroup = 4;
const PaginationTable = ({ total, pageSize, pageNumber, getPage }) => {
  const [groupIndex, setGroupIndex] = useState(0);
  const paginationList =
    total % pageSize > 0
      ? Math.floor(total / pageSize) + 1
      : Math.floor(total / pageSize);
   const paginationGroupList =
    paginationList % paginationGroup > 0
      ? Math.floor(paginationList / paginationGroup) + 1
      : Math.floor(paginationList / paginationGroup);
  return (
    <div className="pagination">
    <ul className="pagination-list">

     {
      paginationList > 1 ? <Fragment>
      {
        paginationGroupList > 1 ? <Fragment>{
           groupIndex > 0 ? <Fragment><li className="pagination-list__item" onClick={() => {
            getPage(1);
            setGroupIndex(0);
            }}>&#8249;&#8249;</li></Fragment> :   <Fragment><li className="pagination-list__item disable">&#8249;&#8249;</li></Fragment>
      }</Fragment> : ""
      }
      {
        groupIndex > 0 ? <li className="pagination-list__item" onClick={() => {
          setGroupIndex(groupIndex - 1);
          getPage((groupIndex - 1)*paginationGroup + 1);
          }
          }>	&#8249;</li> : ""
      }
      {renderPagination(paginationList, pageNumber, getPage, groupIndex)}
      {
        groupIndex < paginationGroupList - 1 ? <li className="pagination-list__item" onClick={() => {
          setGroupIndex(groupIndex + 1);
          getPage((groupIndex + 1)*paginationGroup + 1);
          }
          }>&#8250;</li> : ""
      }
      {
        paginationGroupList > 1 ? <Fragment>{
           groupIndex < paginationGroupList - 1 ? <Fragment><li className="pagination-list__item" onClick={() => {
            getPage((paginationGroupList - 1)*paginationGroup + 1);
            setGroupIndex(paginationGroupList - 1);
          }}>&#8250;&#8250;</li> </Fragment>:   <Fragment><li className="pagination-list__item disable">&#8250;&#8250;</li></Fragment>

      }</Fragment> : ""
      }
        </Fragment> : ""}
      </ul>
    </div>
  );
};
export default PaginationTable;

const renderPagination = (paginationList, pageNumber, getPage, groupIndex ) => {
  var paginationHtml = [];
  for (let i = groupIndex*paginationGroup + 1; i <= groupIndex*paginationGroup + paginationGroup; i++) {
    if(i <= paginationList){
      paginationHtml.push(
        <li className={`pagination-list__item ${pageNumber === i ? "active" : ""}`} key={i} onClick={() => getPage(i)}>
          {i}
        </li>
      );
    }
   
  }
 
  return paginationHtml;
};
