import React, { Fragment, useState } from "react";
import { BsModal } from "../../../../components/bases/shared";
import BlockLoginModal from "./blockLoginModal";
import SendEmailModal from "./sendEmailModal";
import OnLoginModal from "./onLoginModal";
import SendSmsModal from "./sendSmsModal";
import ContractShowModal from "./contractShowModal";
import ActiveCTVModal from "./activeCTVModal";
import { useSelector, useDispatch } from 'react-redux';
import { setToggleModal } from "./../../../../states/duck/pages/investorsListReducer/actions";
import {  setLockUnlockLoginSuccess, setSendEmailSmsSuccess, showContractDetailApi, setActiveCTVSuccess } from "./../../../../states/duck/pages/investorsListReducer/actions"
   


const ModalList = () => {
  const dispatch = useDispatch();
  const [showModal, setModalType] = useState("");
   const toggleModal =  useSelector(state => state.investorsList.toggleModal);
  const listChose = useSelector(state => state.investorsList.listContractChoose);

  const showModalType = value => {
    if( value === "show-contract" ){
      dispatch(showContractDetailApi(listChose[0].id))
    }
    setModalType(value);
    dispatch(setToggleModal(true));
  };

 
  

  return (
    <Fragment>
      <div className="table-tools">
      {
        listChose.length > 0 ? (
          <Fragment>
            <button
              className="table__btn blue__btn"
              type="button"
              onClick={() => showModalType("show-contract")}
            >
              Xem hợp đồng
            </button>

            {
              listChose[0].lockReason !== "0" ? (
                <button
                    className="table__btn orange__btn"
                    type="button"
                    onClick={() => showModalType("on-login")}
                  >
                    Mở đăng nhập
                </button>
              ): (
                <button
                    className="table__btn red__btn"
                    type="button"
                    onClick={() => showModalType("block-login")}
                  >
                    Chặn đăng nhập
                </button>
              )
            }
            <button
                className="table__btn green__btn"
                type="button"
                onClick={() => showModalType("send-sms")}
              >
                Gửi sms
            </button>
            <button
                className="table__btn yellow__btn"
                type="button"
                onClick={() => showModalType("send-email")}
              >
                Gửi email
            </button>
             {
              !listChose[0].referralCode  ? (
                <button
                  className="table__btn orange__btn"
                  type="button"
                  onClick={() => showModalType("active-ctv")}
                >
                    Active CTV
                </button>
              ) : ""}
          </Fragment>
        ): (
          ""
        )
      }
      </div>
      <BsModal
        className={showModal === "show-contract" ? "show-contract" : ""}
        toggleModal={toggleModal}
        onClick={() => dispatch(setToggleModal(false))}
      >
        {showModal === "block-login" ? (
          <BlockLoginModal closeModal={() => {
              dispatch(setToggleModal(false));
              dispatch(setLockUnlockLoginSuccess(false));
            }
          } />
        ) : showModal === "show-contract" ? (
          <ContractShowModal closeModal={() => dispatch(setToggleModal(false))} />
        ) : showModal === "on-login" ? (
          <OnLoginModal closeModal={() => {
              dispatch(setToggleModal(false));
              dispatch(setLockUnlockLoginSuccess(false));
            }
          } />
        ) : showModal === "send-sms" ? (
          <SendSmsModal closeModal={() => {
              dispatch(setToggleModal(false));
              dispatch(setSendEmailSmsSuccess(false));
            }
          } />
        ) : showModal === "send-email" ? (
          <SendEmailModal closeModal={() => {
              dispatch(setToggleModal(false));
              dispatch(setSendEmailSmsSuccess(false));
            }
          } />
        ): showModal === "active-ctv" ? (
          <ActiveCTVModal closeModal={() => {
              dispatch(setToggleModal(false));
              dispatch(setActiveCTVSuccess(false));
            }
          } />
        ): (
                    ""
                  )}
      </BsModal>
    </Fragment>
  );
};

export default ModalList;
