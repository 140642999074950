export const URL = "info/get";
export const URL_TYPE = "withdraws";
export const URL_SEARCH = "search/manager";
export const GET_LIST = "GET_WITHDRAW_MANAGER_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_WITHDRAW_MANAGER_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_WITHDRAW_MANAGER_SIZE";
export const SET_TOTAL_LIST = "GET_WITHDRAW_MANAGER_LIST_TOTAL";
export const SET_FILTER = "SET_WITHDRAW_MANAGER_FILTER";
export const SET_MODAL = "SET_WITHDRAW_MANAGER_MODAL";


