

import React from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { useSelector, useDispatch } from 'react-redux';
import {getUserApi, getPageNumber, setChooseList} from "./../../../states/duck/pages/createAccountReducer/actions";

const TableList = () => {
 const dispatch = useDispatch();
  const listChose = useSelector(state => state.createAccount.listUserChoose);
  const choseCheckBox = (e, id) => {
    var newListChose = []
    if(e.target.checked){
      newListChose.push(id);
    }
    dispatch(setChooseList(newListChose));
  };
  const tableList = useSelector(state => state.createAccount.tableList);
  const total = useSelector(state => state.createAccount.total);
  const pageSize = useSelector(state => state.createAccount.pageSize);
  const pageNumber = useSelector(state => state.createAccount.pageNumber);
  const getPage = (number) => {
    dispatch(getUserApi(pageSize, number));
    dispatch(getPageNumber(number));

  }
    const triggerCheckBox = (item) => {
    const oldId = listChose.length > 0 ? listChose[0].id : "";
      var newListChose = []
      if(oldId !== item.id){
         newListChose.push(item);
      }
      dispatch(setChooseList(newListChose));
  }

  return (
      <div className="table-content create-table">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th></th>
              <th>Email</th>
              <th>Tên nhân viên</th>
              <th>Quyền hạn</th>
              <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                return (
                  <tr key={index} onClick={() => triggerCheckBox(item)}>
                    <td>
                      <input type="checkbox" className="select__box" onChange={(e)=>choseCheckBox(e,item)} checked={listChose[0] && listChose[0].id === item.id ? "checked" : ""}/>
                    </td>
                    <td className="no-wrap">{item.email}</td>
                    <td>{item.name}</td>
                    <td style={{
                      minWidth: "150px",
                      textAlign: "justify"
                    }}>{item.permissions.map((item1, index)=>{
                      return <span key={index}>{
                        item1.toString() === "1" ? "Trang chủ" :
                        item1.toString() === "2" ? "danh sách đầu tư" :
                        item1.toString() === "3" ? "danh sách hợp đồng" :
                        item1.toString() === "4" ? "quản lý rút tiền" :
                        item1.toString() === "5" ? "quản lý thanh lý" :
                        item1.toString() === "6" ? "xử lý đầu tư" :
                        item1.toString() === "7" ? "xử lý thanh lý" :
                        item1.toString() === "8" ? "xử lý rút tiền" :
                        item1.toString() === "9" ? "tạo tài khoản quản trị" :
                        item1.toString() === "10" ? "cấu hình hệ thống" :
                        item1.toString() === "11" ? "tra cứu lịch sử" :
                        ""
                      }{index !== item.permissions.length - 1 ? "," : ""} </span>
                    })}</td>
                    <td>{item.isLocked ? "Hoạt động" : "Chờ duyệt"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
         <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;

