import React, { useState } from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { BsModal } from "../../../components/bases/shared";
import WithdrawProcessModal from "./modalList/withdrawProcessModal";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../bases/configs"
import {getContractApi, getPageNumber, setToggleModal, setWithDrawStatusApi, setSuccessActive, getSearchApi} from "./../../../states/duck/pages/withdrawProcessReducer/actions";



const TableList = () => {
   const dispatch = useDispatch();
  const adminId = JSON.parse(localStorage.getItem("adminId")).adminId;
  const [modalType, setModalType] = useState("");
  const showModalType = value => {
    setModalType(value);
    dispatch(setToggleModal(true));
    dispatch(setWithDrawStatusApi({
      "withdrawId": value.id,
      "status": "waitSign"
    }));
    
  };
  const toggleModal = useSelector(state => state.withdrawProcess.toggleModal);
  const tableList = useSelector(state => state.withdrawProcess.tableList);
  const total = useSelector(state => state.withdrawProcess.total);
  const pageSize = useSelector(state => state.withdrawProcess.pageSize);
  const pageNumber = useSelector(state => state.withdrawProcess.pageNumber);
    const filter = useSelector(state => state.withdrawProcess.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
       dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));
  }
  return (
      <div className="table-content process-table">
      <div className="table">
        <table>
          <thead>
          <tr>
            <th>Họ tên <br/> nhà đầu tư</th>
            <th>Số tiền rút</th>
            <th>Hình thức</th>
            <th>Loại rút</th>
            <th>Ngày yêu cầu rút</th>
            <th>Người xử lý</th>
            <th>Trạng thái</th>
            <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.investorName}</td>
                  <td className="no-wrap">{transformNumber(item.amount)}</td>
                  <td>{item.transferType === "bank" ? "Chuyển khoản" : "Tiền mặt"}</td>
                  <td>{item.type === "profit" ? "Rút lãi" : item.type === "bonus" ? "Rút thưởng" : ""}</td>
                  <td>{transformDateToDateFormal(item.createdAt)}</td>
                  <td>{item.adminName}</td>
                  <td>{item.status === "pending" ? "Chưa xử lý" : item.status === "waitSign" ? "chờ ký phiếu chi" : item.status="waitTransfer" ? "chờ chuyển tiền" : ""}</td>
                  <td>
                  {
                    item.processAdmin !== adminId && item.adminName !== null ? <button className={`process__btn  gray__btn`} type="button">Trả tiền</button> : <button className="process__btn" type="button" onClick={() => showModalType(item)}>Trả tiền</button>
                  }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
        <BsModal
        className="process-modal"
        toggleModal={toggleModal}
        onClick={() =>  {
           dispatch(setToggleModal(false));  
           dispatch(setSuccessActive(false));
            if(filter.type){
              getSearchApi(filter,pageSize,pageNumber)
            }else{
              dispatch(getContractApi(pageSize, pageNumber));
            }
           }
        }
      >
       { toggleModal ?
         <WithdrawProcessModal itemProcess={modalType} closeModal={() =>{
           dispatch(setToggleModal(false));  dispatch(setSuccessActive(false));
          if(filter.type){
              getSearchApi(filter,pageSize,pageNumber)
            }else{
              dispatch(getContractApi(pageSize, pageNumber));
            }
           }}/> : ""
       }
      </BsModal>
          <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;