export const URL = "info/get";
export const URL_TYPE = "contracts";
export const URL_SEARCH = "search";
export const GET_LIST = "GET_CONTRACT_MANAGER_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_CONTRACT_MANAGER_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_CONTRACT_MANAGER_SIZE";
export const SET_TOTAL_LIST = "GET_CONTRACT_MANAGER_LIST_TOTAL";
export const SET_FILTER = "SET_CONTRACT_MANAGER_FILTER";
export const SET_MODAL = "SET_CONTRACT_MANAGER_MODAL";


