import * as constants from './constants';
import {callApi} from './../../../common';


export const getHistoryApi = (body, pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${pageSize}/${pageNumber}`, "POST", body, function(res){
            dispatch(getHistory(res.data.results));
            dispatch(getPageSize(pageSize));
            dispatch(getTotalList(res.data.total));
            dispatch(showTableByType(true));
            dispatch(getFilter(body));
    });
}
export const getContractNumberByNameApi = (body) => {
    console.log("investor", body)
    return dispatch =>  callApi(`${constants.URL_GET_CONTRACT_BY_NAME}`, "POST",body, function(res){
        dispatch(getContractNumberByName(res.data.contractNumber));
    });
}


export const getContractNumberByName = (value) => {
    return {
        type: constants.SET_CONTRACT_NUMBER_BY_NAME,
        value
    }
}
export const refreshContractNumberByName = () => {
    return {
        type: constants.REFRESH_CONTRACT_NUMBER_BY_NAME,
    }
}
export const getHistory = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const getTotalList = (value) => {
    return {
        type: constants.SET_TOTAL_LIST,
        value
    }
}
export const getPageNumber = (value) => {
    return {
         type: constants.SET_PAGE_NUMBER,
        value
    }
}
export const getPageSize = (value) => {
    return {
         type: constants.SET_PAGE_SIZE,
        value
    }
}
export const getFilter = (value) => {
    return {
        type: constants.SET_FILTER,
        value
    }
}
export const showTableByType = (value) => {
    return {
        type: constants.SET_SHOW_TABLE_BY_TYPE,
        value
    }
}


