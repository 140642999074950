

import React from "react";
import PaginationTable from "../../../bases/shared/paginationTable";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber,transformDateToDateFormal} from "./../../../bases/configs"
import {getHistoryApi, getPageNumber} from "./../../../../states/duck/pages/historyReducer/actions";
const InterestWithdrawTableList = () => {
   const dispatch = useDispatch();
  const tableList = useSelector(state => state.history.tableList);
  const filter = useSelector(state => state.history.filter)
  const total = useSelector(state => state.history.total);
  const pageSize = useSelector(state => state.history.pageSize);
  const pageNumber = useSelector(state => state.history.pageNumber);
  const getPage = (number) => {
    dispatch(getHistoryApi(filter, pageSize, number));
    dispatch(getPageNumber(number));

  }
  return (
      <div className="table-content interest-withdraw">
        <div className="table">
          <table>
            <thead>
            <tr>
               <th>Tên nhà đầu tư</th>
              <th>CMND</th>
              <th>Số tiền rút</th>
              <th>Ngày rút lãi</th>
              <th>Hình thức rút</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                return (
                  <tr key={index}>
                     <td>{ item.investor }</td>
                    <td>{item.docId}</td>
                    <td className="no-wrap">{transformNumber(item.amount)}</td>
                    <td>{transformDateToDateFormal(item.createdAt)}</td>
                    <td>{item.transferType === "bank" ? "Chuyển khoản" : item.transferType === "money" ? "Tiền mặt" : ""}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
          <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default InterestWithdrawTableList;

