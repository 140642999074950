import React, { useState } from "react";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { useSelector, useDispatch } from 'react-redux';
import {getSearchApi, getPageSize, getPageNumber} from "./../../../states/duck/pages/liquidationManagerReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants";
import {formatDate} from "./../../../states/common/index"



// const option1 = [
//     { value: 1, label: 'Giám sát kinh doanh' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' }
//   ]



const FilterList = () => {
 const [name, setName] = useState("");
 const [contractNumber, setContractNumber] = useState("");
  const [processPerson, setProcessPerson] = useState("");
  const [startDate, setStartDate] = useState(new Date("2019/11/01"));
  const [endDate, setEndDate] = useState(new Date());
  const onStartDateChange = date => {
    setStartDate(date);
  };
  const onEndDateChange = date => {
    setEndDate(date);
  };
   const pageSize = useSelector(state => state.contractManager.pageSize);
  const [list, setList] = useState(pageSize);
  const [transferType, setTranserType] = useState("");
  const dispatch = useDispatch();
 const onSubmit = () => {
    dispatch(getSearchApi({
      "name": name,
      "contractNumber": contractNumber,
      "processor": processPerson,
      "startDate": formatDate(startDate),
      "endDate": formatDate(endDate),
      "type":'finishes'
    },list, 1));
    dispatch(getPageSize(list));
    dispatch(getPageNumber(1));
  }
  return (
    <div className="search-content liquidation-content" style={{paddingRight: 0}}>
      <div className="bs-row row-xs-10">
      <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Tên nhà đầu tư</label>
            <input type="text" className="search__input" placeholder="" name="name" onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Mã hợp đồng</label>
            <input type="text" className="search__input" placeholder="" name="setContractNumber" onChange={(e) => setContractNumber(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Người xử lý</label>
            <input type="text" className="search__input" placeholder="" name="name" value={processPerson} onChange={(e) => setProcessPerson(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-25-10 xs-50-10" >
            <div className="item">
            <label htmlFor="">Từ ngày</label>
              <DatePicker onChange={onStartDateChange} value={startDate} />
            </div>
          </div>
          <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
            <div className="item">
            <label htmlFor="">Đến ngày</label>
              <DatePicker onChange={onEndDateChange} value={endDate} />
            </div>
          </div>
           <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hình thức</label>
            <Select options={transferTypeOption} name="transferType"  value={transferTypeOption.filter(option => option.value === transferType)} onChange={ (newValue) => setTranserType(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="" style={{opacity: 0}}>button</label>
             <button className="search__btn" type="button" onClick={ onSubmit } style={{
               position: "relative",
             }}>Lọc</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterList;
const transferTypeOption = [
    { value: '', label: 'Tất cả' },
    { value: 'bank', label: 'Chuyển khoản' },
    { value: 'money', label: 'Tiền mặt' },
]

