
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputMoney from './inputMoney';
import CirProgress from './cirProgress';
import RelativeLink from "./../../bases/shared/relativeLink.js";
import { BsModal } from "../../../components/bases/shared";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import {  useDispatch } from 'react-redux';
import {getInfoApi, setToggleModal} from "./../../../states/duck/pages/homeReducer/actions";


const moneyTotalType = [ {
            type: "invest",
            title: "Tổng tiền đã nhận từ các nhà đầu tư"
        },
        {
            type: "profitPay",
            title: "Tổng tiền đã trả lãi cho các nhà đầu tư"
        },
        {
            type: "bonusPay",
            title: "Tổng tiền đã trả thưởng cho các nhà đầu tư"
        },
        {
            type: "investorWithdraw",
            title: "Tổng tiền các nhà đầu tư đã rút"
        },
        {
            type: "profitWithdraw",
            title: "Tổng tiền lãi đã rút"
        },
        {
            type: "bonusWithdraw",
            title:  "Tổng tiền thưởng đã rút"
        },
        {
            type: "finishNoEndTerm",
            title: "Tổng hợp đồng thanh lý trước hạn" 
        },
        {
            type: "finishEndTerm",
            title: "Tổng hợp đồng thanh lý đúng kỳ hạn"
        },
        {
            type: "finishAmount", 
            title: "Tổng tiền gốc đã hoàn trả nhà đầu tư"
        },
        {
            type: "predictProfit",
            title: "Tổng tiền dự kiến phải trả lãi ở tháng tiếp theo"
        }]
const HomePage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
         dispatch(getInfoApi({
            "type": "userRole"
        }))
    }, [dispatch]);
 
    const toggleModal = useSelector(state => state.home.toggleModal);
    const [indexModal, setIndexModal] = useState(0);
    const infoPosition = useSelector(state => state.home.infoPosition);
    console.log(infoPosition[5]);
    const infoPositionArr = [0,0,0,0,0];
        if(infoPosition[2]){
            infoPositionArr[0] = infoPosition[1]
        }
        if(infoPosition[3]){
            infoPositionArr[1] = infoPosition[2]
        }
        if(infoPosition[4]){
            infoPositionArr[2] = infoPosition[3]
        }
        if(infoPosition[5]){
            infoPositionArr[3] = infoPosition[4]
        }
        // if(infoPosition[5]){
        //     infoPositionArr[4] = infoPosition[5]
        // }
    const total = infoPositionArr.reduce((a,b) => a + b, 0);
    return (
        <Fragment>
            <RelativeLink />
            <section className="section-statistic">
                <div className="module module-statistic">
                    <div className="module-header">
                        <h2 className="title"><img src="images/title_img.png" alt="" /> Thống kê </h2>
                    </div>
                    <div className="module-content">
                        <div className="statistic-position">
                            <div className="content-sub">Thống kê chức vụ kinh doanh</div>
                            <div className="list-chart">
                                {DataChart.map((item, index) => <CirProgress key={index}  item={item} total={total} value={infoPositionArr[index]} index={index + 1} showModal={() => dispatch(setToggleModal(!toggleModal))} setIndexModal={setIndexModal}/>)}
                            </div>
                            <div className="list-input-money">
                                <div className="bs-row">
                                    {moneyTotalType.map((item, index) => <InputMoney key={index} item={item}/>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BsModal className="modal-home" toggleModal={toggleModal} onClick={() => dispatch(setToggleModal(false))}>
                    <h2 className="title-modal">Danh sách những nhà đầu tư đạt chức vụ <span>{DataChart[indexModal].name}</span></h2>
                    <TableContent />
                </BsModal>
            </section>
        </Fragment>
    );
}
export default HomePage;
/// data
const DataChart = [
    // { index: 0, name: "quản lý", color_1: "07a4e6", color_2: "0dddfc" },
    { index: 0, name: "Nhân viên", color_1: "f78300", color_2: "ffc500" },
    { index: 1, name: "phó phòng", color_1: "e50532", color_2: "fe4584" },
    { index: 3, name: "trưởng phòng", color_1: "d50df1", color_2: "7a64f4" },
    { index: 3, name: "giám đốc", color_1: "12e089", color_2: "92ff5d" },
]


const TableContent = () => {
    const valueModal = useSelector(state => state.home.listPositionDetail);
        if (valueModal.length === 0) valueModal.push({ name: "no match" });
    return <div className="table-content">
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>Họ và tên</th>
                        <th>CMND</th>
                        <th>Email</th>
                        <th>Điện thoại</th>
                    </tr>
                </thead>
                <tbody>
                    {valueModal.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="no-wrap">{item.name}</td>
                                <td>{item.docId}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
        <PaginationTable />
    </div>
}