import React, { useState } from "react";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { useSelector, useDispatch } from 'react-redux';
import {getSearchApi, getPageSize, getPageNumber} from "./../../../states/duck/pages/withdrawManagerReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants";
import {formatDate} from "./../../../states/common/index"



// const option1 = [
//     { value: 1, label: 'Giám sát kinh doanh' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' }
//   ]


const FilterList = () => {
  // const [name, setName] = useState("");
  const [processPerson, setProcessPerson] = useState("");
  const [withdrawPerson, setWithdrawPerson] = useState("");
  const [startDate, setStartDate] = useState(new Date("2019/11/01"));
  const [endDate, setEndDate] = useState(new Date());
  
  const onStartDateChange = date => {
    setStartDate(date);
  };
  const onEndDateChange = date => {
    setEndDate(date);
  };
   const pageSize = useSelector(state => state.contractManager.pageSize);
  const [list, setList] = useState(pageSize);
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(getSearchApi({
      "contractNumber": "",
      "processor": processPerson,
      "startDate": formatDate(startDate),
      "endDate": formatDate(endDate),
      "type":'withdraws'
    },list, 1));
    dispatch(getPageSize(list));
    dispatch(getPageNumber(1));
  }
  return (
    <div className="search-content liquidation-content">
      <div className="bs-row row-sm-5 row-lg-10 row-xs-10">
      {
        // <div className="bs-col sm-20-10">
        //   <div className="item">
        //     <label htmlFor="">Mã hợp đồng</label>
        //     <input type="text" className="search__input" placeholder="" name="name" value={name} onChange={(e) => setName(e.target.value)}/>
        //   </div>
        // </div>
      }
        <div className="bs-col lg-20-10 sm-20-5 xs-50-10">
          <div className="item">
            <label htmlFor="">Người xử lý</label>
            <input type="text" className="search__input" placeholder="" name="name" value={processPerson} onChange={(e) => setProcessPerson(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-20-5 xs-50-10">
          <div className="item">
            <label htmlFor="">Người rút tiền</label>
            <input type="text" className="search__input" placeholder="" name="withdrawPerson" value={withdrawPerson} onChange={(e) => setWithdrawPerson(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col lg-20-10 sm-20-5 xs-50-10">
            <div className="item">
            <label htmlFor="">Từ ngày</label>
              <DatePicker onChange={onStartDateChange} value={startDate} />
            </div>
          </div>
          <div className="bs-col lg-20-10 sm-20-5 xs-50-10">
            <div className="item">
            <label htmlFor="">Đến ngày</label>
              <DatePicker onChange={onEndDateChange} value={endDate} />
            </div>
          </div>
        <div className="bs-col lg-20-10 sm-20-5 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
      </div>
      <button className="search__btn" type="button" onClick={ onSubmit }>Lọc</button>
    </div>
  );
};

export default FilterList;

