import React, { Fragment, useEffect} from 'react';
import CreateForm from "./createForm";
import TableList from "./tableList";
import RelativeLink from "./../../bases/shared/relativeLink.js";
import ModalList from "./modalList";
import {PAGE_SIZE} from "./../../../states/common/constants"
import { useDispatch } from 'react-redux';
import { getUserApi } from "./../../../states/duck/pages/createAccountReducer/actions";
const CreateAccountPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserApi(PAGE_SIZE, 1));
    }, [dispatch, ])
    return (
        <Fragment>
        <RelativeLink title="Tạo tài khoản quản trị" />
        <section className="section-list">
            <div className="module module-list">
                <div className="module-header">
                    <h2 className="title"><img src="images/title_img.png" alt=""/> Tạo tài khoản quản trị</h2>
                </div>
                <div className="module-content">
                    <CreateForm />
                    <ModalList />
                    <TableList />
                </div>
            </div>
        </section>
        </Fragment>
    );
}

export default CreateAccountPage;
