import React, { useState } from "react";
import PaginationTable from "./../../bases/shared/paginationTable.js";
import { BsModal } from "../../../components/bases/shared";
import ContractImagesModal from "./modalList/contractImagesModal";
import InfoCashModal from "./modalList/infoCashModal";
import InfoTransferModal from "./modalList/infoTransferModal";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../bases/configs";
import {getContractApi, getPageNumber, setToggleModal, getSearchApi} from "./../../../states/duck/pages/contractManagerReducer/actions";


const TableList = () => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState("");
  const [contractSelect, setContractSelect] = useState("")
  const showModalType = value => {
    setModalType(value);
    dispatch(setToggleModal(true));
  };
  const toggleModal = useSelector(state => state.contractManager.toggleModal);
  const tableList = useSelector(state => state.contractManager.tableList);
  const total = useSelector(state => state.contractManager.total);
  const pageSize = useSelector(state => state.contractManager.pageSize);
  const pageNumber = useSelector(state => state.contractManager.pageNumber);
  const filter = useSelector(state => state.contractManager.filter);
  const getPage = (number) => {
    if(filter.type){
      dispatch(getSearchApi(filter,pageSize,number));
    }else{
      dispatch(getContractApi(pageSize, number));
    }
    dispatch(getPageNumber(number));
  }
  return (
      <div className="table-content">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th>Mã HĐ</th>
              <th>Họ và tên <br/>Nhà đầu tư</th>
              <th>Tiền đầu tư</th>
              <th>Kỳ hạn</th>
              <th>Kích hoạt</th>
              <th>Hết kỳ hạn</th>
              <th>Hình thức</th>
              <th>Người kích hoạt</th>
              <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                if(item.endDate !== null){
                  var newDate = new Date().getTime();
                  var enDate = new Date(item.endDate).getTime();
                  var longDay = Math.floor((enDate - newDate)/(24*3600000));
                }
                return (
                  <tr key={index}>
                    <td className="no-wrap"><button className="show__info" type="button" onClick={() => {showModalType("contractCode"); setContractSelect(item)}}>{item.contractNumber}</button></td>
                    <td>{item.investorName}</td>
                    <td className="no-wrap">{transformNumber(item.amount)}</td>
                    <td className="no-wrap">{item.term > 0 ? `${item.term} năm` : "Không kỳ hạn"}</td>
                    <td>{transformDateToDateFormal(item.activeDate)}</td>
                    <td>{transformDateToDateFormal(item.endDate)}</td>
                    <td>{item.transferType === "bank" ? <button className="show__info" type="button" onClick={() => {showModalType(0); setContractSelect(item)}}>Chuyển khoản</button> : <button className="show__info" type="button" onClick={() => {showModalType(1); setContractSelect(item)}}>Tiền mặt</button>}</td>
                    <td>{item.adminName}</td>
                    <td>{longDay < 90 ? "Sắp hết hạn" : (item.status === "pending" ? "Chờ kích hoạt" : item.status === "active" ? "Kích hoạt" : item.status === "waitFinish" ? "Chờ thanh lý" : item.status === "end" ? "Đã thanh lý" : "")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <BsModal
        className={modalType === "contractCode" ? "contract-img" : modalType === 0 ? "transform-trade" : "cash-trade"}
        toggleModal={toggleModal}
        onClick={() => dispatch(setToggleModal(false))}
      >
        {modalType === "contractCode" ? (
          <ContractImagesModal  contractSelect={contractSelect}/>
        ) : modalType === 0 ? (
          <InfoTransferModal contractSelect={contractSelect}/>
        ) : modalType === 1 ? (
          <InfoCashModal  contractSelect={contractSelect}/>
        ) : (
          ""
        )}
      </BsModal>
        <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default TableList;