import { transformNumber } from "./index"
const convertThreeNumberToWord = (value) => {
    let number = ["không","một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
    let numberUnit = ["", "mốt", "hai", "ba", "tư", "lăm", "sáu", "bảy", "tám", "chín"];
    let textArr = value.toString().split("").reverse();
    let textResultArr = [];
    for(let i = textArr.length - 1; i >= 0;i--){
        if(i === 2){
            if(Number(textArr[1]) > 0 || Number(textArr[0]) > 0 || Number(textArr[2]) > 0){
                textResultArr.push(number[Number(textArr[i])] + " trăm");
            }
        }else if(i === 1){
            if(Number(textArr[i]) === 1){
                    textResultArr.push("mười")
            }else if (Number(textArr[i]) === 0){
                if( Number(textArr[0]) > 0){
                        textResultArr.push(" lẻ")
                }else{
                        textResultArr.push("")
                }
            } else{
                textResultArr.push(number[Number(textArr[i])] + " mươi");
            }
        }else {
            if(Number(textArr[1]) > 1){
                    textResultArr.push(numberUnit[Number(textArr[i])]);
            }else if(Number(textArr[1]) === 1){
                if(Number(textArr[i]) !== 0){
                    textResultArr.push(number[Number(textArr[i])]);
                }else{
                    textResultArr.push("");
                }
            }else{
                if(textArr[i] > 0){
                    textResultArr.push(number[Number(textArr[i])]);
                }else{
                    textResultArr.push("");
                }
            }
        }
    }
    textResultArr.map(item => item.trim())
    return textResultArr.join(" ");
}



const convertNumberToWord = (number) => {
    let result;
    let text = ["", "nghìn", "triệu", "tỉ"];
    let valueProcess = transformNumber(number);
    let textArr = valueProcess.split(",").reverse();
    const valueResult = [];
    for(let i = textArr.length - 1; i >= 0; i--){
        let showText = false;
        let run = i-1;
        while(run >= 0){
            if(Number(textArr[run]) > 0){
                showText = true
            };
            run--
        }
        if((Number(textArr[i]) > 0 || showText) && convertThreeNumberToWord(textArr[i]).trim() !== ""){
            valueResult.push(convertThreeNumberToWord(textArr[i])+ " " + text[i])
        }
    }
    result = valueResult.join(" ").replace(/\s+/g, " ").trim();
    if(result[0]){
        result = result.replace(result[0], result[0].toUpperCase());
    }
    if(result === ""){
        return "Không"
    }
    return result;
}
const convertNumberPercentToWord = (number, config) => {
    let integerNumber, decimalNumber;
    let  result = "";
    const numberString = number.toString();
    const index = numberString.indexOf(".");
    if(index > 0){
        integerNumber = numberString.substr(0, index);
        decimalNumber = numberString.substr(index+1);
        let integerNumberTransform = convertNumberToWord(decimalNumber);
        result = convertNumberToWord(integerNumber) + " phẩy " + integerNumberTransform.replace(integerNumberTransform[0], integerNumberTransform[0].toLowerCase());
    }else{
        integerNumber = number;
        decimalNumber = "";
        result = convertNumberToWord(integerNumber);
    }
    if(config === "lowercase"){
        result = result.replace(result[0], result[0].toLowerCase());
    }
    return result;
}

export{
    convertNumberToWord,
    convertNumberPercentToWord
}

