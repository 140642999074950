import * as constants from './constants';
import {callApi} from './../../../common';


export const getContractApi = (pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${pageSize}/${pageNumber}`, "POST", null, function(res){
            dispatch(getContract(res.data.users));
            dispatch(getTotalList(res.data.total));
            dispatch(setChooseList([]));
    });
}
export const showContractDetailApi = (id) => {
    return dispatch =>  callApi(`${constants.URL_SHOW_CONTRACT}/${id}`, "GET", null, function(res){
            dispatch(getContractDetail(res.data));
    });
}

export const getSendEmailSms = (body) => {
    return dispatch =>  callApi(`${constants.URL_SEND_EMAIL_SMS}`, "POST", body, function(res){
        dispatch(setSendEmailSmsSuccess(true));
    });
}


export const setLockUnlockLogin = (body) => {
    return dispatch =>  callApi(`${constants.URL_LOCK_UNLOCK_LOGIN}`, "POST", body, function(res){
        dispatch(setLockUnlockLoginSuccess(true));
        dispatch(setReplaceStatus(body));

    });
}

export const getSearchApi = (body, pageSize, pageNumber) => {
    return dispatch =>  callApi(`${constants.URL}/${pageSize}/${pageNumber}`, "POST", body, function(res){
        dispatch(getContract(res.data.users));
        dispatch(getTotalList(res.data.total));
        dispatch(getFilter(body));
    });
}
export const getActiveCTVApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_ACTIVE_CTV}`, "POST", body, function(res){
        dispatch(setActiveCTVSuccess(true));
        dispatch(setReplaceActiveCTV(body));
    });
}








export const getContract = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const getContractDetail = (value) => {
    return {
        type: constants.GET_LIST_DETAIL,
        value
    }
}
export const getTotalList = (value) => {
    return {
        type: constants.SET_TOTAL_LIST,
        value
    }
}
export const getPageNumber = (value) => {
    return {
         type: constants.SET_PAGE_NUMBER,
        value
    }
}
export const getPageSize = (value) => {
    return {
         type: constants.SET_PAGE_SIZE,
        value
    }
}
export const getFilter = (value) => {
    return {
        type: constants.SET_FILTER,
        value
    }
}

export const setToggleModal = (value) => {
    return {
        type: constants.SET_MODAL,
        value
    }
}

export const setLockUnlockLoginSuccess = (value) => {
    return {
        type: constants.SET_LOCK_UNLOCK_LOGIN_SUCCESS,
        value
    }
}
export const setSendEmailSmsSuccess = (value) => {
    return {
        type: constants.SET_SEND_EMAIL_SMS_SUCCESS,
        value
    }
}
export const setActiveCTVSuccess = (value) => {
    return {
        type: constants.SET_ACTIVE_CTV_SUCCESS,
        value
    }
}

export const setChooseList = (value) => {
    return {
        type: constants.SET_CHOOSE_LIST,
        value
    }
}

export const setReplaceStatus = (value) => {
    return {
        type: constants.SET_REPLACE_STATUS,
        value
    }
}
export const setReplaceActiveCTV = (value) => {
    return {
        type: constants.SET_REPLACE_ACTIVE_CTV,
        value
    }
}


