import React from "react";
import PaginationTable from "../../../bases/shared/paginationTable";
import { useSelector, useDispatch } from 'react-redux';
import {transformNumber, transformDateToDateFormal} from "./../../../bases/configs"
import {getHistoryApi, getPageNumber} from "./../../../../states/duck/pages/historyReducer/actions";
const InvestTableList = () => {
   const dispatch = useDispatch();
  const tableList = useSelector(state => state.history.tableList);
  const filter = useSelector(state => state.history.filter)
  const total = useSelector(state => state.history.total);
  const pageSize = useSelector(state => state.history.pageSize);
  const pageNumber = useSelector(state => state.history.pageNumber);
   console.log("filter", filter)
  const getPage = (number) => {
    console.log("filter1", filter)
    dispatch(getHistoryApi(filter, pageSize, number));
    dispatch(getPageNumber(number));

  }
  return (
      <div className="table-content invest-history">
        <div className="table">
          <table>
            <thead>
            <tr>
              <th>Mã hợp đồng</th>
              <th>Tên nhà đầu tư</th>
              <th>CMND</th>
              <th>Số tiền đầu tư</th>
              <th>Ngày kích hoạt</th>
              <th>Kỳ hạn đầu tư</th>
              <th>Người kích hoạt</th>
              </tr>
            </thead>
            <tbody>
              {tableList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="no-wrap">{item.contractNumber}</td>
                    <td>{item.investor}</td>
                    <td>{ item.docId }</td>
                    <td>{transformNumber(item.amount)}</td>
                    <td className="no-wrap">{transformDateToDateFormal(item.activeDate)}</td>
                    <td>{item.term > 0 ? `${item.term} năm` : "Không kỳ hạn"}</td>
                    <td>{item.processor}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
          <PaginationTable total={total} pageSize={pageSize} pageNumber={pageNumber} getPage={getPage}/>
      </div>
  );
};

export default InvestTableList;

