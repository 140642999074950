import React, { Fragment} from 'react';
import FilterList from "./filterList";
import RelativeLink from "./../../bases/shared/relativeLink.js";
import { BonusTableList, BonusWithdrawTableList, InterestTableList, InterestWithdrawTableList, InvestTableList, LiquidationTableList } from "./tableList";
import { useSelector } from 'react-redux';
import * as constants from "./../../../states/duck/pages/historyReducer/constants"
const HistoryPage = () => {
    const typeHistory = useSelector(state => state.history.filter.type);
  const tableType = useSelector(state => state.history.tableType);

    return (
        <Fragment>
        <RelativeLink title="Tra cứu lịch sử"/>
        <section className="section-list">
            <div className="module module-list">
                <div className="module-header">
                    <h2 className="title"><img src="images/title_img.png" alt=""/> Tra cứu lịch sử</h2>
                </div>
                <div className="module-content">
                    <FilterList />
                    {
                        tableType ? <Fragment>{
                        typeHistory === constants.INVEST_HISTORY ? <InvestTableList/> :
                        typeHistory === constants.FINISH_HISTORY ? <LiquidationTableList /> :
                        typeHistory === constants.PROFIT_HISTORY ? <InterestTableList /> :
                        typeHistory === constants.BONUS_HISTORY ? <BonusTableList /> :
                        typeHistory === constants.WITHDRAW_PROFIT_HISTORY ? <InterestWithdrawTableList /> :
                        typeHistory === constants.WITHDRAW_BONUS_HISTORY ? <BonusWithdrawTableList /> :
                        ""
                    }</Fragment>: ""
                    }
                   
                    
                </div>
            </div>
        </section>
        </Fragment>
    );
}

export default HistoryPage;
