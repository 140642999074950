import React, { useState } from "react";
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import {getSearchApi, getPageSize,getPageNumber} from "./../../../states/duck/pages/withdrawProcessReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"



const option1 = [
    { value: "", label: 'Tất cả' },
    { value: 'profit', label: 'Rút lãi' },
    { value: 'bonus', label: 'Rút thưởng'},
  ]
const option2 = [
    { value: "", label: 'Tất cả' },
    { value: 'pending', label: 'Chưa xử lý' },
    { value: 'waitSign', label: 'Chờ ký phiếu chi'},
    { value: 'waitTransfer', label: 'Chờ chuyển tiền' }
  ]


const FilterList = () => {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [withdrawalType, setWithdrawalType] = useState("");
   const pageSize = useSelector(state => state.withdrawProcess.pageSize);
  const [list, setList] = useState(pageSize);
  const dispatch = useDispatch();
   const onSubmit = () => {
    dispatch(getSearchApi({
      name,
      status: position,
      withdrawType: withdrawalType,
      type: "withdraw",
    },list, 1));
    dispatch(getPageSize(list));
    dispatch(getPageNumber(1));
  }
 
  return (
    <div className="search-content">
      <div className="bs-row row-xs-10">
        <div className="bs-col sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Tên nhà đầu tư</label>
            <input type="text" className="search__input" placeholder="" name="name" onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="bs-col sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Loại rút</label>
            <Select options={option1} name="withdrawalType"  value={option1.filter(option => option.value === withdrawalType)} onChange={ (newValue) => setWithdrawalType(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Trạng thái hợp đồng</label>
            <Select options={option2} name="position"  value={option2.filter(option => option.value === position)} onChange={ (newValue) => setPosition(newValue.value)}/>
          </div>
        </div>
        <div className="bs-col sm-25-10 xs-50-10">
          <div className="item">
            <label htmlFor="">Hiển thị</label>
            <Select options={OPTION_PAGE_SIZE} name="list"  value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={ (newValue) => setList(newValue.value)}/>
          </div>
        </div>
      </div>
      <button className="search__btn" type="button" onClick={ onSubmit }>Lọc</button>
    </div>
  );
};

export default FilterList;

