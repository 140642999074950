import React, {useState, useEffect} from 'react';
import * as actions from './../../../states/duck/pages/loginPage/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {decode} from "jsonwebtoken";
import { routerCollection } from "./../../layout"




const LoginPage = () => {
    const [email, setEmail] = useState(""); //minhdb@bytesoft.net
    const [password, setPassword] = useState(""); //abc123
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    let history = useHistory();
    useEffect(()=>{
        if(token !== ""){
            const permissions = decode( token.slice(7, token.length)).permissions;
            history.push(routerCollection[Number(permissions[0]) - 1].path);
        }
    }, [history, token])
    return (
        <div className="login">
            <div className="login-content">
                <div className="bs-row">
                 <div className="bs-col sm-50 lg-order-2 md-order-2 sm-order-2">
                        <div className="login-logo">
                            <a href="https://bytesoft.vn/" className="link">
                            <img src="images/logo_login.gif" alt=""/></a>
                        </div>
                    </div>
                    <div className="bs-col sm-50">
                        <div className="login-form">
                            <div className="head">
                                <p className="title">Đăng nhập</p>
                            </div>
                            <div className="content">
                                <div className="item">
                                    <label htmlFor="">Tên đăng nhập</label>
                                    <input type="text" className="login__input" name="email"  onChange={e => setEmail(e.target.value)}/>
                                </div>
                                <div className="item">
                                    <label htmlFor="">Mật khẩu</label>
                                    <input className="login__input" type="password" name="password" onChange={e => setPassword(e.target.value)}/>
                                </div>
                                <button className="login__btn" type="button" onClick={() => dispatch(actions.signIn(email, password))}>Đăng nhập</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
