import React, { Fragment } from "react";
import {URL} from "./../../../../states/common/constants";
import Iframe from 'react-iframe';
import {transformNumber, transformDateToDateFormal} from "./../../../bases/configs";

const ModalList = ({ closeModal, contractSelect, modalType }) => {
  return (
    <Fragment>
      <div className="content">
        <div className="withdraw-img">
          <div className="withdraw-content">
            <div className="img">
              {modalType === "report" ? (
                <Fragment>
                  { contractSelect.finishContractScanUrl !== null ? <Iframe url={`${URL}/${contractSelect.finishContractScanUrl}`} className="iframe" display="initial" /> : <img src="images/transform_img.jpg" alt=""/>}
                  <p className="desc">Biên bản</p>
                </Fragment>
              ) : (
                <Fragment>
                  { contractSelect.recipeScanUrl !== null ? <Iframe url={`${URL}/${contractSelect.recipeScanUrl}`} className="iframe" display="initial" /> : <img src="images/transform_img.jpg" alt=""/>}
                  <p className="desc">Phiếu chi</p>
                </Fragment>
              )}
            </div>
            <div className="text">
              <div className="text-content">
                <div className="item">
                  <p className="label">Mã Hợp đồng</p>
                  <p className="value">{contractSelect.contractNumber}</p>
                </div>
                <div className="item">
                  <p className="label">Người nhận tiền gốc</p>
                  <p className="value">{contractSelect.investorName}</p>
                </div>
                <div className="item">
                  <p className="label">Số tiền gốc nhận</p>
                  <p className="value">{transformNumber(contractSelect.finishAmount)}</p>
                </div>
                <div className="item">
                  <p className="label">Ngày xuất tiền</p>
                  <p className="value">{transformDateToDateFormal(contractSelect.processDate)}</p>
                </div>
                <div className="item">
                  <p className="label">Người xử lý</p>
                  <p className="value">{contractSelect.adminName}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <button
          className="bottom__btn green__btn"
          type="button"
          onClick={closeModal}
        >
          Ok
        </button>
      </div>
    </Fragment>
  );
};

export default ModalList;
