import React, { useState } from "react";
import useForm from 'react-hook-form';
import * as yup from "yup";
import {  useDispatch } from 'react-redux';
import {createAccountApi} from "./../../../states/duck/pages/createAccountReducer/actions"


const CreateForm = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  home: yup.boolean().default(() => false).required(),
  invest: yup.boolean().default(() => false).required(),
  contract: yup.boolean().default(() => false).required(),
  withdrawManager: yup.boolean().default(() => false).required(),
  liquidationManager: yup.boolean().default(() => false).required(),
  investProcess: yup.boolean().default(() => false).required(),
  liquidationProcess: yup.boolean().default(() => false).required(),
  withdrawProcess: yup.boolean().default(() => false).required(),
  createAccount: yup.boolean().default(() => false).required(),
  settingSystem: yup.boolean().default(() => false).required(),
  statisticalProcess: yup.boolean().default(() => false).required(),
});








const FilterList = () => { 
  const dispatch = useDispatch();
  const [triggerList, setTriggerList] = useState(false);
  const { register, errors, handleSubmit, getValues } = useForm({
    mode: "onChange",
    validationSchema: CreateForm
  });




  const onSubmit = (data) => {
    const list = { ...data };
    var listResult = [];
    delete list.email; delete list.name;
    if (!triggerListValidate()) {
       for (var i in list){
      if(list[i] === true){
          convertToArr(i,listResult)
          }
        }
      listResult = listResult.sort((a,b) => a - b);
      dispatch(createAccountApi({
        name: data.name,
        email: data.email,
        permissions: listResult
      }))
    }
  }

  const triggerListValidate = () => {
    const dataCurrent = Object.values(getValues());
    let shouldTrigger = true;
    dataCurrent.forEach((item) => {
      if (item === true) shouldTrigger = false;
    })

    if (shouldTrigger) {
      setTriggerList(true);
      return true
    } else {
      setTriggerList(false);
      return false
    }
  }
  return (
    <div className="create-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bs-row row-sm-15">
          <div className="bs-col sm-50-15">
            <div className="item">
              <label >Email đăng nhập</label>
              <input className="search__input"
                name="email"
                type="email"
                ref={register({
                  required: "email vaild"
                })}
              />
              {errors.email && <p>Nhập lại Email</p>}
            </div>
          </div>
          <div className="bs-col sm-50-15">
            <div className="item">
              <label >Tên nhân viên</label>
              <input className="search__input"
                name="name"
                type="text"
                ref={register({
                  required: "name valid"
                })}
              />
              {errors.name && <p>Nhập lại Tên</p>}
            </div>
          </div>
          <div className="bs-col sm-100-15">
            <div className="item">
              <label htmlFor="" className="special">Quyền hạn</label>
              <div className="checkbox-list" name="list" ref={register}>
                 <input type="checkbox" id="home" name="home" onChange={() => triggerListValidate()} ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="home" >Trang chủ</label>
                <input type="checkbox" id="invest" name="invest" onChange={() => triggerListValidate()} ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="invest" >Danh sách đầu tư</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="contract" name="contract" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="contract">Danh sách hợp đồng</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="withdrawManager" name="withdrawManager" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="withdrawManager">Quản lý rút tiền</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="liquidationManager" name="liquidationManager" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                 <label htmlFor="liquidationManager">Quản lý thanh lý</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="investProcess" name="investProcess" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                 <label htmlFor="investProcess">Xử lý đầu tư</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="liquidationProcess" name="liquidationProcess" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="liquidationProcess">Xử lý thanh lý</label>
                   <input onChange={() => triggerListValidate()} type="checkbox" id="withdrawProcess" name="withdrawProcess" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                 <label htmlFor="withdrawProcess">Xử lý rút tiền</label>
                   <input onChange={() => triggerListValidate()} type="checkbox" id="createAccount" name="createAccount" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="createAccount">Tạo tài khoản quản trị</label>
                   <input onChange={() => triggerListValidate()} type="checkbox" id="settingSystem" name="settingSystem" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="settingSystem">Cấu hình hệ thống</label>

                <input onChange={() => triggerListValidate()} type="checkbox" id="statisticalProcess" name="statisticalProcess" ref={register({
                  required: {
                    message: "checkbox"
                  }
                })} />
                <label htmlFor="statisticalProcess">Tra cứu lịch sử</label>

              </div>
              {triggerList && <p>Chọn ít nhất một quyền hạn</p>}
            </div>
          </div>
        </div>
        <button className="create__btn" type="submit" onSubmit={() => {
          onSubmit()
        }}>Khởi tạo tài khoản</button>
      </form>
    </div>
  );
};

export default FilterList;


const convertToArr = (run, arr) => {
   switch (run) {
     case "home":
    arr.push(1);
    break;
   case "invest":
    arr.push(2);
    break;
  case "contract":
  arr.push(3);
    break;
  case "withdrawManager":
    arr.push(4);
    break;
  case "liquidationManager":
    arr.push(5);
    break;
  case "investProcess":
    arr.push(6);
    break;
    case "liquidationProcess":
    arr.push(7);
    break;
  case "withdrawProcess":
  arr.push(8);
    break;
  case "createAccount":
    arr.push(9);
    break;
  case "settingSystem":
    arr.push(10);
    break;
  case "statisticalProcess":
    arr.push(11);
    break;
  default:
      break;

  }
}