import React, { useState } from "react";
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import {getSearchApi, getPageSize, getPageNumber} from "./../../../states/duck/pages/liquidationProcessReducer/actions";
import { OPTION_PAGE_SIZE } from "./../../../states/common/constants"
const option1 = [
     { value: "", label: 'Tất cả' },
    { value: 'pending', label: 'Chưa xử lý' },
    { value: 'waitSign', label: 'Chờ ký xác nhận'},
    { value: 'waitTransfer', label: 'Chờ hoàn tiền' }
]
 
const FilterList = (props) => {
     const [name, setName] = useState("");
  const [position, setPosition] = useState("");
   const pageSize = useSelector(state => state.contractManager.pageSize);
  const [list, setList] = useState(pageSize);
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(getSearchApi({
      name,
      status: position,
      type: "finish"
    },list, 1));
    dispatch(getPageSize(list));
    dispatch(getPageNumber(1));
  }
    return (
        <div className="search-content">
            <div className="bs-row row-xs-10">
                <div className="bs-col sm-40-10 xs-50-10">
                    <div className="item">
                        <label htmlFor="">Mã hợp đồng/Tên nhà đầu tư</label>
                        <input type="text" className="search__input"  name="name" onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className="bs-col sm-30-10 xs-50-10">
                    <div className="item">
                        <label htmlFor="">Trạng thái hợp đồng</label>
                        <Select options={option1} name="position" value={option1.filter(option => option.value === position)} onChange={(newValue) => setPosition(newValue.value)} />
                    </div>
                </div>
                <div className="bs-col sm-30-10 xs-50-10">
                    <div className="item">
                        <label htmlFor="">Hiển thị</label>
                        <Select options={OPTION_PAGE_SIZE} name="list" value={OPTION_PAGE_SIZE.filter(option => option.value === list)} onChange={(newValue) => setList(newValue.value)} />
                    </div>
                </div>
            </div>
            <button className="search__btn" type="button" onClick={onSubmit}>Lọc</button>
        </div>
    );
};

export default FilterList;
