import * as constants from "./constants";
import PNotify from "pnotify/dist/es/PNotify";
const axios = require("axios");

export const callApi = (url, method, data, success) => {
  const token = localStorage.getItem("token");
  return axios({
    method: method,
    url: `${constants.URL}/${url}`,
    headers: {
      Authorization: token
    },
    data: data
  })
    .then(function(res) {
      success(res);
    })
    .catch(function(err) {
      if (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.localMessage
        ) {
          PNotify.error({
            title: "Lỗi",
            text: err.response.data.localMessage,
            destroy: true,
            delay: 3000
          });
        } else if (err.response) {
          PNotify.error({
            title: "Lỗi",
            text: err.response.statusText,
            destroy: true,
            delay: 3000
          });
        }
      }
    });
};

export const callLoginApi = (url, method, data, success) => {
  return axios({
    method: method,
    url: `${constants.URL}/${url}`,
    data: data
  })
    .then(function(res) {
      success(res);
    })
    .catch(function(err) {
      if (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.localMessage
        ) {
          PNotify.error({
            title: "Lỗi",
            text: err.response.data.localMessage,
            destroy: true,
            delay: 3000
          });
        } else if (err.response) {
          PNotify.error({
            title: "Lỗi",
            text: err.response.statusText,
            destroy: true,
            delay: 3000
          });
        }
      }
    });
};

export const formatDate = date => {
  if (date) {
    var dateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);
    return dateString;
  }
};

export const formatOnlyDate = date => {
  if (date) {
    var dateString =
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2);
    return dateString;
  }
};
