export const URL = "info/get";
export const URL_TYPE = "withdraws";
export const URL_WITHDRAW_STATUS = "withdraw/status";
export const URL_WITHDRAW_PROCESS = "withdraw/handle";
export const URL_SEARCH = "search";




export const GET_LIST = "GET_WITHDRAW_PROCESS_LIST";
export const SET_PAGE_NUMBER = "SET_PAGE_WITHDRAW_PROCESS_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_WITHDRAW_PROCESS_SIZE";
export const SET_TOTAL_LIST = "GET_WITHDRAW_PROCESS_LIST_TOTAL";
export const SET_FILTER = "SET_WITHDRAW_PROCESS_FILTER";
export const SET_MODAL = "SET_WITHDRAW_PROCESS_MODAL";
export const SET_ACTIVE_SUCCESS = "SET_WITHDRAW_PROCESS_SUCCESS";
export const SET_REPLACE_AFTER_SUCCESS = "SET_WITHDRAW_REPLACE_AFTER_SUCCESS";


