import * as types from './constants';
import * as common from "./../../../common/constants"
var initialState = {
    filter: false,
    toggleModal: false,
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    total: "",
    tableList: [],
    sendEmailSmsSuccess: false,
    lockUnlockLoginSuccess: false,
    activeCTVSuccess: false,
    listContractChoose: [],
    detailsTableList: {
        user: {name: "", docId: "", phone: "", email: "", address: ""},
        contracts:[]
    }
};
var reducer = (state = initialState, action) => {
    console.log("action", action)
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.GET_LIST_DETAIL:
            return {...state, detailsTableList: action.value};
        case types.SET_TOTAL_LIST:
            return {...state, total: action.value};
        case types.SET_PAGE_NUMBER:
            return {...state, pageNumber: action.value};
        case types.SET_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_PAGE_SIZE:
            return {...state, pageSize: action.value};
        case types.SET_SEND_EMAIL_SMS_SUCCESS:
            return {...state, sendEmailSmsSuccess: action.value};
        case types.SET_ACTIVE_CTV_SUCCESS:
            return {...state, activeCTVSuccess: action.value};
        case types.SET_LOCK_UNLOCK_LOGIN_SUCCESS:
            return {...state, lockUnlockLoginSuccess: action.value};
        case types.SET_CHOOSE_LIST:
            return {...state, listContractChoose: action.value};
        case types.SET_REPLACE_STATUS:
            var tableListMirror = [...state.tableList];
            var index = -1;
            for(var i = 0; i < tableListMirror.length; i++){
                if(tableListMirror[i].id === action.value.userId){
                    index = i;
                }
            }
            if(index >= 0){
                if(action.value.type === "lock"){
                   tableListMirror[index].lockReason = "1";
                }else{
                    tableListMirror[index].lockReason = "0";
                }
            }
            return {...state, tableList: tableListMirror};
        case types.SET_REPLACE_ACTIVE_CTV:
            tableListMirror = [...state.tableList];
            index = -1;
            for(i = 0; i < tableListMirror.length; i++){
                if(tableListMirror[i].id === action.value.userId){
                    index = i;
                }
            }
            if(index >= 0){
                tableListMirror[index].referralCode = "success";
            }
            return {...state, tableList: tableListMirror};
        case types.SET_FILTER:
            return {...state,
                filter: action.value
            };
        default:
            return state;
    }
}
export default reducer;